export const updateData = (
  series: Highcharts.SeriesOptionsType[],
  chart: Highcharts.Chart,
  extremes: { start: Date; end: Date },
) => {
  for (const serie of series) {
    const matchingSeries = chart.series.find(
      (_serie) => _serie.name === serie.name,
    );

    if (matchingSeries) {
      matchingSeries.update(serie, false);
    } else {
      chart.addSeries(serie, false);
    }
  }

  const names = new Set(series.map((serie) => serie.name!));
  for (const serie of chart.series) {
    if (!names.has(serie.name)) {
      serie.remove(false);
    }
  }

  chart.xAxis[0].setExtremes(
    extremes.start.getTime(),
    extremes.end.getTime(),
    false,
  );

  chart.redraw();
};
