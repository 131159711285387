// TODO[@mui/material@>=6.0.0] use Button from @mui/material instead of @mui/lab/LoadingButton
import { getErrorMessage } from "@elax/utils";
import Button from "@mui/lab/LoadingButton";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";
import type { DialogProps, PaperProps } from "@mui/material";
import type { AxiosRequestConfig } from "axios";
import { enqueueSnackbar } from "notistack";
import type { ReactNode } from "react";
import type { FormProps, RaRecord } from "react-admin";
import { Form, useRecordContext, useRefresh } from "react-admin";
import { useAxiosMutation } from "../providers/data-hooks/useAxiosQuery";

export type EditFormWithDialogProps = {
  dialogProps?: DialogProps & { title?: string; close?: () => void };
};

type EditFormDialogProps<RecordType, FormRecordType> = {
  getMutationAxiosConfig: (record: RecordType) => AxiosRequestConfig;
  getFormRecordFromRecord?: (record: RecordType) => FormRecordType | undefined;
  additionalDefaultValues?: Partial<FormRecordType>;
  children: NonNullable<ReactNode>;
} & EditFormWithDialogProps;

function FormWithPaper<RecordType>({
  record,
  defaultValues,
  onSubmit,
  children,
  ...paperProps
}: Pick<
  FormProps<RecordType>,
  "record" | "defaultValues" | "onSubmit" | "children"
> &
  PaperProps) {
  return (
    <Paper {...paperProps}>
      <Form<RecordType> {...{ record, defaultValues, onSubmit, children }} />
    </Paper>
  );
}

export function EditFormDialog<
  // eslint-disable-next-line @typescript-eslint/no-restricted-types -- need to be compatible with useRecordContext generic
  RecordType extends RaRecord | Omit<RaRecord, "id">,
  FormRecordType,
>({
  getMutationAxiosConfig,
  getFormRecordFromRecord,
  children,
  additionalDefaultValues,
  dialogProps,
}: EditFormDialogProps<RecordType, FormRecordType>) {
  if (!dialogProps) {
    throw new Error(
      "dialogProps is required. Make sure you passed the component to FieldItem properly.",
    );
  }

  const record = useRecordContext<RecordType>();
  const refresh = useRefresh();
  const { mutate, isPending } = useAxiosMutation(
    record ? getMutationAxiosConfig(record) : {},
    {
      onSuccess: () => {
        refresh();
        dialogProps.close?.();
      },
      onError: (error) => {
        enqueueSnackbar(
          <Alert severity="error">{`Failed to save data: ${getErrorMessage(
            error,
          )}`}</Alert>,
        );
      },
    },
  );

  if (!record) {
    return null;
  }

  return (
    <Dialog
      open={dialogProps.open}
      onClose={dialogProps.onClose}
      PaperComponent={FormWithPaper as any} // https://mui.com/material-ui/react-dialog/#form-dialogs
      PaperProps={{
        record: getFormRecordFromRecord?.(record) ?? {},
        defaultValues: {
          ...getFormRecordFromRecord?.(record),
          ...additionalDefaultValues,
        } as any,
        onSubmit: (data: unknown) => {
          mutate(data);
        },
      }}
    >
      <DialogTitle>{dialogProps.title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={dialogProps.close}>Cancel</Button>
        <Button type="submit" variant="contained" loading={isPending}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
