import { SelectInput } from "react-admin";
import {
  InterventionStatus,
  InterventionVisitStatus,
  interventionsVisitStatusLabels,
} from "../types";

export const interventionPossibleVisitStatusByStatus: Record<
  InterventionStatus,
  InterventionVisitStatus[]
> = {
  [InterventionStatus.NOT_BOOKED]: [InterventionVisitStatus.NOTHING_TO_REPORT],
  [InterventionStatus.TO_BE_PLANNED]: [
    InterventionVisitStatus.NOTHING_TO_REPORT,
  ],
  [InterventionStatus.PLANNED]: [InterventionVisitStatus.NOTHING_TO_REPORT],
  [InterventionStatus.SUCCESS]: [InterventionVisitStatus.NOTHING_TO_REPORT],
  [InterventionStatus.FAILED]: [
    InterventionVisitStatus.ABSENT_END_USER,
    InterventionVisitStatus.RGPD_REFUSAL,
    InterventionVisitStatus.PRODUCT_REFUSAL,
    InterventionVisitStatus.UNKNOWN_REFUSAL,
    InterventionVisitStatus.INSTALLATION_CURRENTLY_NOT_POSSIBLE,
    InterventionVisitStatus.INSTALLATION_NOT_POSSIBLE,
    InterventionVisitStatus.NOT_VISITED,
  ],
};

export const InterventionVisitStatusInput = () => {
  return (
    <SelectInput
      source="visitStatus"
      label="Visited Status"
      choices={Object.values(InterventionVisitStatus).map((value) => ({
        id: value,
        name: interventionsVisitStatusLabels[value],
      }))}
      fullWidth
    />
  );
};
