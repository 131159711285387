import { SelectInput } from "react-admin";
import { InterventionSourcingStatus } from "../types";

export const InterventionSourcingStatusInput = () => {
  return (
    <SelectInput
      source="sourcingStatus"
      label="Sourcing Status"
      choices={Object.values(InterventionSourcingStatus).map((value) => ({
        id: value,
        name: value,
      }))}
      fullWidth
    />
  );
};
