import { Autocomplete, TextField } from "@mui/material";
import { useGetList } from "react-admin";
import type { AutocompleteInputProps } from "react-admin";
import { z } from "zod";
import { useURLFilter } from "../../../filters/useURLFilter";
import type { Organization } from "../../organization/types";
import type { DevicesFilter } from "../types";

export const SelectOrganization = (props: AutocompleteInputProps) => {
  const { data } = useGetList<Organization>("organizations", {
    pagination: { perPage: 1000, page: 1 },
  });
  const [value, setValue] = useURLFilter<DevicesFilter["organizationId"]>(
    "organizationId",
    z.array(z.string()),
  );

  const selectedOrganizationIds = new Set(value);

  const selectedOptions = data?.filter((o) =>
    selectedOrganizationIds.has(o.id),
  );

  return (
    <Autocomplete
      value={selectedOptions ?? []}
      options={data ?? []}
      getOptionLabel={(o) => o.name}
      renderInput={(params) => <TextField {...params} label={props.label} />}
      loading={!data}
      multiple
      onChange={(_, value) =>
        value.length > 0
          ? setValue(value.map((v) => v.id))
          : setValue(undefined)
      }
    />
  );
};
