import { IncidentActor } from "@elax/business-types";
import { SelectInput } from "react-admin";
import { useWatch } from "react-hook-form";
import {
  InterventionAccounting,
  interventionAccountingLabels,
} from "../../../intervention/types";

type InterventionAccountingInputProps = {
  source: string;
};

export const InterventionAccountingInput = ({
  source,
}: InterventionAccountingInputProps) => {
  const assignedTo = useWatch({ name: "assignedTo" });

  if (assignedTo !== IncidentActor.MAINTENANCE_PROVIDER) {
    return null;
  }

  return (
    <SelectInput
      fullWidth
      label="Intervention accounting"
      source={source}
      choices={[
        InterventionAccounting.MAINTENANCE,
        InterventionAccounting.REINTERVENTION_INSTALLATION,
        InterventionAccounting.REINTERVENTION_MAINTENANCE,
      ].map((value) => ({
        id: value,
        name: interventionAccountingLabels[value],
      }))}
    />
  );
};
