import { useQuery } from "@tanstack/react-query";
import type { DailyStats } from "../../resources/devices/types";
import { axiosClient } from "../data";

export const useDeviceDailyStats = (
  deviceId?: string,
  options?: { enabled: boolean },
) => {
  const route = `/devices/${deviceId}/daily-stats`;
  return useQuery({
    queryKey: [route],
    queryFn: () => axiosClient.get<DailyStats[]>(route).then((res) => res.data),
    enabled: options?.enabled && !!deviceId,
  });
};
