import { FunctionField } from "ra-ui-materialui";
import { getDocumentURL } from "../documentsHelpers";
import type { Document } from "../types";

export const DocumentLinkField = ({ bucketName }: { bucketName: string }) => {
  return (
    <FunctionField
      label="Link"
      render={(record: Document) => (
        <a
          href={getDocumentURL(bucketName, record)}
          target="_blank"
          rel="noreferrer"
        >
          Open
        </a>
      )}
    />
  );
};
