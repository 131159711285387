import type { Housing } from "../../resources/housing/types";
import { useAxiosQuery } from "./useAxiosQuery";

export const useDemandResponseEntityDetails = (
  demandResponseEntityId: string | undefined,
) =>
  useAxiosQuery<{
    totalCapacityInMW: number;
    pdlsWithNoHousing: string[];
    housingCount: number;
    housingsSample: Housing[];
  }>(
    { url: `/demand-response-entities/${demandResponseEntityId}/details` },
    { enabled: Boolean(demandResponseEntityId) },
  );
