import { Box, Tooltip } from "@mui/material";
import { mapValues } from "lodash";
import { FunctionField, Link } from "react-admin";
import { EnumField } from "../../../fields/EnumField";
import type {
  InterventionStatus,
  InterventionType,
} from "../../intervention/types";
import {
  interventionStatusColors,
  interventionStatusLabels,
  interventionTypeColors,
} from "../../intervention/types";
import type { Incident } from "../types";
import { incidentActorLabels } from "../types";

type AssignedToFieldProps = {
  label?: string;
};

export const AssignedToField = ({ label }: AssignedToFieldProps) => {
  return (
    <FunctionField<Incident>
      label={label ?? "Assigned To"}
      render={(incident) => {
        return (
          <Box sx={{ position: "relative" }}>
            <EnumField
              source="assignedTo"
              colors={mapValues(
                incidentActorLabels,
                ({ color }) => color["400"],
              )}
              labels={mapValues(incidentActorLabels, ({ label }) => label)}
            />
            {incident?.device?.lastMaintenance && (
              <Link
                to={`/interventions/${incident.device.lastMaintenance._id}/show`}
              >
                <Tooltip
                  title={`La dernière maintenance dans ce logement est de type "${
                    incident.device.lastMaintenance.type
                  }" et a pour statut "${
                    interventionStatusLabels[
                      incident.device.lastMaintenance
                        .status as InterventionStatus
                    ]
                  }"`}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      right: -10,
                      top: -10,
                      width: 20,
                      height: 20,
                      borderRadius: 3,
                      backgroundColor:
                        interventionStatusColors[
                          incident?.device?.lastMaintenance
                            ?.status as InterventionStatus
                        ] || ("white" as any),
                      borderColor:
                        interventionTypeColors[
                          incident?.device?.lastMaintenance
                            ?.type as InterventionType
                        ] || ("white" as any),
                      borderWidth: 2,
                      borderStyle: "solid",
                    }}
                  />
                </Tooltip>
              </Link>
            )}
          </Box>
        );
      }}
    />
  );
};
