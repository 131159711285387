export enum IncidentActor {
  UNIDENTIFIED = "UNIDENTIFIED",
  MAINTENANCE_PROVIDER = "MAINTENANCE_PROVIDER",
  RESIDENT = "RESIDENT",
  LANDLORD = "LANDLORD",
  DATA_TEAM = "DATA_TEAM",
  TECH_TEAM = "TECH_TEAM",
  OPS_TEAM = "OPS_TEAM",
}

export enum IncidentStatus {
  OPENED = "OPENED",
  CLOSED = "CLOSED",
}
