import GroupIcon from "@mui/icons-material/JoinFull";
import { GroupCreate, GroupEdit } from "./Edit";
import { GroupList } from "./List";
import { GroupShow } from "./Show";
import type { Group } from "./types";

export const groupResource = {
  list: GroupList,
  show: GroupShow,
  edit: GroupEdit,
  create: GroupCreate,
  icon: GroupIcon,
  recordRepresentation: (record: Group) => record.groupName,
};

export { default as GroupIcon } from "@mui/icons-material/JoinFull";
