import HousingIcon from "@mui/icons-material/DoorFront";
import { HousingCreate, HousingEdit } from "./edit/Edit";
import { HousingList } from "./list/List";
import { HousingShow } from "./show/Show";
import type { Housing } from "./types";

export const housingResource = {
  icon: HousingIcon,
  list: HousingList,
  edit: HousingEdit,
  create: HousingCreate,
  show: HousingShow,
  recordRepresentation: (record: Housing) => `🏠 ${record.unitId}`,
};

export { default as HousingIcon } from "@mui/icons-material/DoorFront";
