import { Grid } from "@mui/material";
import { DateInput, NullableBooleanInput } from "react-admin";
import type { EditFormWithDialogProps } from "../../../display/EditFormDialog";
import { EditFormDialog } from "../../../display/EditFormDialog";
import type { ConsentType, EndUser } from "../type";

type EditConsentDialogProps = EditFormWithDialogProps & {
  consentType: ConsentType;
};

export function EditConsentDialog({
  consentType,
  dialogProps,
}: EditConsentDialogProps) {
  return (
    <EditFormDialog<EndUser, EndUser["consents"]>
      dialogProps={dialogProps}
      getMutationAxiosConfig={(record) => ({
        method: "PUT",
        url: `/end-users/${record?.id}/consents`,
      })}
      getFormRecordFromRecord={(record) => record.consents}
    >
      <Grid container>
        <Grid item sm={12}>
          <NullableBooleanInput
            label="Consent"
            source={`${consentType}.consent`}
            sx={{ margin: 1 }}
          />
          <DateInput
            label="Date"
            source={`${consentType}.date`}
            defaultValue={new Date()}
            sx={{ margin: 1 }}
          />
        </Grid>
      </Grid>
    </EditFormDialog>
  );
}
