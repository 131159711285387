import DealIcon from "@mui/icons-material/BusinessCenter";
import { DealList } from "./List";
import { DealShow } from "./Show";
import type { Deal } from "./type";

export const dealResource = {
  icon: DealIcon,
  list: DealList,
  show: DealShow,
  recordRepresentation: (record: Deal) => record.name,
};

export { default as DealIcon } from "@mui/icons-material/BusinessCenter";
