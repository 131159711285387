import { upperFirst } from "lodash";
import {
  useGetRecordRepresentation,
  useRecordContext,
  useResourceDefinition,
} from "react-admin";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

export const PageTitle = ({
  forcedTitle,
  additionalTitle,
}: {
  forcedTitle?: string;
  additionalTitle?: string;
}) => {
  const location = useLocation();
  const isEditPage = /^\/[a-z-]+\/[0-9a-z]{24}$/.test(location.pathname);
  const isCreatePage = /^\/[a-z-]+\/create$/.test(location.pathname);
  const pageName = isEditPage ? "Edit" : isCreatePage ? "Create" : "";

  const resource = useResourceDefinition();
  const resourceName =
    resource?.options?.label ??
    resource?.name?.split("-").map(upperFirst).join(" ");

  const record = useRecordContext();
  const getRecordRepresentation = useGetRecordRepresentation(resource?.name);
  const recordName = getRecordRepresentation(record)?.toString();

  const title = [pageName, recordName, additionalTitle, resourceName, "BO"]
    .filter((s) => !!s)
    .join(" - ");

  return (
    <Helmet>
      <title>{forcedTitle ?? title}</title>
      <meta property="og:title" content={forcedTitle ?? title} />
    </Helmet>
  );
};
