import { IncidentActor } from "@elax/business-types";
import { ReferenceInput, SelectInput } from "react-admin";
import { useWatch } from "react-hook-form";

type MaintenanceProviderInputProps = {
  source: string;
};

export const MaintenanceProviderInput = ({
  source,
}: MaintenanceProviderInputProps) => {
  const assignedTo = useWatch({ name: "assignedTo" });

  if (assignedTo !== IncidentActor.MAINTENANCE_PROVIDER) {
    return null;
  }

  return (
    <ReferenceInput reference="maintenance-providers" source={source}>
      <SelectInput fullWidth label="Maintenance Provider" />
    </ReferenceInput>
  );
};
