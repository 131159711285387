import { Box, Grid, Typography } from "@mui/material";
import {
  Datagrid,
  EditButton,
  Form,
  Pagination,
  ReferenceArrayField,
  ReferenceManyField,
  Show,
  TabbedShowLayout,
  TextField,
} from "react-admin";
import { InlineList } from "../../fields/InlineListField";
import { PageTitle } from "../../layout/PageTitle";
import { ActionsMenu, EditAction } from "../../layout/actions-menu";
import { SmartParameters } from "../device-config/parameters/smart";
import { SmartHPHCParameters } from "../device-config/parameters/smart-hphc";
import { NameField } from "../end-users/fields/NameField";

export const DeviceConfigPersonaShow = () => {
  return (
    <Show actions={<PersonaActions />}>
      <PageTitle />
      <Box display="flex" justifyContent="center" margin={2}>
        <TextField source="name" variant="h4" />
      </Box>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="summary">
          <Grid container spacing={1} padding={1}>
            <Grid item xs={12} display="flex" justifyContent="center">
              <TextField
                source="description"
                variant="body1"
                display="flex"
                justifyContent="center"
              />
            </Grid>
            <Grid item xs={12}>
              <Form>
                <Typography variant="h5" marginBottom={2}>
                  Parameters HP/HC
                </Typography>

                <SmartHPHCParameters
                  source="parameters_hp_hc"
                  disabled={true}
                />

                <Typography variant="h5" marginBottom={2}>
                  Parameters Base
                </Typography>
                <SmartParameters source="parameters_base" disabled={true} />
              </Form>
            </Grid>
          </Grid>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Users">
          <ReferenceManyField
            reference="end-users"
            target="deviceConfigPersona"
            pagination={<Pagination />}
          >
            <Datagrid bulkActionButtons={false}>
              <NameField label="name" />
              <ReferenceArrayField
                reference="housings"
                source="housingIds"
                sortable={false}
              >
                <InlineList source="unitId" />
              </ReferenceArrayField>
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const PersonaActions = () => (
  <ActionsMenu>
    <EditAction />
  </ActionsMenu>
);
