import type { ComponentType } from "react";
import { PageTitle } from "./PageTitle";

export function withPageTitle<T extends JSX.IntrinsicAttributes>(
  Component: ComponentType<T>,
) {
  const ComponentWithPageTitle = (props: T) => {
    return (
      <>
        <PageTitle />
        <Component {...props} />
      </>
    );
  };
  return ComponentWithPageTitle;
}
