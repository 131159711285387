import {
  ContactPage,
  EditNote,
  EmailOutlined,
  History,
  HowToReg,
  Phone,
  ThumbUp,
  Today,
} from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { green, purple, red } from "@mui/material/colors";
import {
  ArrayField,
  Datagrid,
  DateField,
  ReferenceArrayField,
  ReferenceField,
  ReferenceManyField,
  TextField,
} from "react-admin";
import { FieldItem } from "../../../../display/FieldItem";
import { EnumField } from "../../../../fields/EnumField";
import { DialogButton } from "../../../../layout/DialogButton";
import { DeviceConfigPersonaIcon } from "../../../device-config-persona";
import { DataTab } from "../../../devices/show/tabs/data";
import { HousingLastProjectField } from "../../../housing/fields/HousingLastProjectField";
import { InterventionStatusField } from "../../../intervention/fields/status";
import { InterventionTypeField } from "../../../intervention/fields/type";
import { EditConsentDialog } from "../../actions/EditConsentDialog";
import { EditContactsDialog } from "../../actions/EditContactsDialog";
import { ConsentField } from "../../fields/ConsentField";
import { ContactSourceField } from "../../fields/ContactSourceField";
import { ContactValidationStatusField } from "../../fields/ContactValidationStatusField";
import { NPSField } from "../../fields/NPSField";
import { ConsentType, consentTypeLabel } from "../../type";

function ContactsField({ source }: { source: string }) {
  return (
    <>
      <ArrayField source={source + ".emails.all"}>
        <Typography
          variant="subtitle1"
          mt={1}
          display="flex"
          alignItems="center"
        >
          <EmailOutlined sx={{ marginRight: 1 }} /> Emails
        </Typography>
        <Datagrid
          bulkActionButtons={false}
          expand={<ContactHistory />}
          size="small"
          rowClick={false}
        >
          <TextField source="emailAddress" label="Email Address" />
          {source === "contacts" && <ContactSourceField label="Source" />}
          <EnumField
            source="status"
            label="Status"
            colors={{
              validated: green[800],
              deleted: red[800],
              invalid: purple[800],
            }}
          />
          <DateField source="createdAt" showTime label="Date" />
        </Datagrid>
      </ArrayField>
      <ArrayField source={source + ".contacts.phoneNumbers.all"}>
        <Typography
          variant="subtitle1"
          mt={1}
          display="flex"
          alignItems="center"
          marginTop={2}
        >
          <Phone sx={{ marginRight: 1 }} /> Phone Numbers
        </Typography>
        <Datagrid
          bulkActionButtons={false}
          expand={<ContactHistory />}
          size="small"
          rowClick={false}
        >
          <TextField source="number" label="Number" />
          {source === "contacts" && <ContactSourceField label="Source" />}
          <EnumField
            source="status"
            label="Status"
            colors={{
              validated: green[800],
              deleted: red[800],
            }}
          />
          <DateField source="createdAt" showTime label="Date" />
        </Datagrid>
      </ArrayField>
    </>
  );
}

export function EndUserShowTab() {
  return (
    <Grid container spacing={2} margin={2}>
      <Grid item xs={12} md={4}>
        <FieldItem
          label="Persona"
          showHistory={true}
          icon={<DeviceConfigPersonaIcon />}
        >
          <ReferenceField
            reference="device-config-personas"
            source="deviceConfigPersona"
          />
        </FieldItem>
        <FieldItem label="NPS" showHistory={true} icon={<ThumbUp />}>
          <NPSField source="nps" />
        </FieldItem>
        <FieldItem label="Creation Date" icon={<Today />}>
          <DateField source="createdAt" showTime />
        </FieldItem>
        <FieldItem label="Notes" icon={<EditNote />}>
          <TextField source="notes" emptyText="-" />
        </FieldItem>
        {Object.values(ConsentType).map((type) => (
          <FieldItem
            key={type}
            label={`Consent ${consentTypeLabel[type]}`}
            icon={<HowToReg />}
            editDialog={<EditConsentDialog consentType={type} />}
          >
            <ConsentField
              source={`consents.${type.toString()}`}
              type={type}
              display="date"
            />
          </FieldItem>
        ))}
      </Grid>
      <Grid item xs={12} md={8}>
        <FieldItem
          label="Contacts"
          icon={<ContactPage />}
          editDialog={<EditContactsDialog />}
        >
          <ContactsField source="contacts" />
        </FieldItem>
      </Grid>
      <Grid item xs={12}>
        <ReferenceArrayField
          reference="housings"
          source="housingIds"
          sort={{ field: "createdAt", order: "ASC" }}
          emptyText={
            '⚠️ No current housing. This end-user will be deleted after one year. History is available in the "Housing history" tab'
          }
        >
          <Typography variant="h6">
            Housings{" "}
            <DialogButton startIcon={<History />} title="History">
              <ArrayField source="housingHistory" emptyText="-">
                <Datagrid bulkActionButtons={false}>
                  <TextField source="type" label="Type" />
                  <DateField source="date" showTime />
                  <ReferenceField
                    source="housingId"
                    reference="housings"
                    link="show"
                  >
                    <TextField source="unitId" />
                  </ReferenceField>
                </Datagrid>
              </ArrayField>
            </DialogButton>
          </Typography>
          <Datagrid
            bulkActionButtons={false}
            expand={
              <>
                <ReferenceManyField
                  reference="interventions"
                  target="housingId"
                  sort={{ field: "datetime", order: "ASC" }}
                  fullWidth
                >
                  <Typography variant="h6">Interventions</Typography>
                  <Datagrid bulkActionButtons={false}>
                    <InterventionTypeField />
                    <InterventionStatusField />
                    <DateField label="Date" source="datetime" showTime />
                  </Datagrid>
                </ReferenceManyField>
                <ReferenceManyField
                  reference="devices"
                  target="housingId"
                  sort={{ field: "datetime", order: "ASC" }}
                  fullWidth
                >
                  <Typography variant="h6">Devices</Typography>
                  <Datagrid bulkActionButtons={false} expand={<DataTab />}>
                    <TextField source="name" />
                  </Datagrid>
                </ReferenceManyField>
              </>
            }
          >
            <TextField source="unitId" label="Unit ID" emptyText="-" />
            <TextField source="address" label="Address" emptyText="-" />
            <HousingLastProjectField label="Last project" />
          </Datagrid>
        </ReferenceArrayField>
      </Grid>
    </Grid>
  );
}

const ContactHistory = () => {
  return (
    <ArrayField source="validationHistory">
      <Datagrid
        bulkActionButtons={false}
        sx={{
          borderLeft: "1px dotted #0f5ef6",
        }}
      >
        <DateField label="Date" source="date" showTime />
        <ContactValidationStatusField label="Validation Status" />
        <ContactSourceField label="Source" />
      </Datagrid>
    </ArrayField>
  );
};
