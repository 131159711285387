import DeviceCertificateIcon from "@mui/icons-material/Badge";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CloudOff from "@mui/icons-material/CloudOff";
import Downloading from "@mui/icons-material/Downloading";
import CAServerIcon from "@mui/icons-material/Security";
import { Tooltip } from "@mui/material";
import { blue, green, orange, red } from "@mui/material/colors";
import dayjs from "dayjs";
import { FunctionField } from "react-admin";
import { satisfies } from "semver";
import type { Device } from "../types";

type CertificateFieldProps = {
  label?: string;
};

export function CertificateField(props: CertificateFieldProps) {
  return (
    <FunctionField<Device>
      render={(record) => {
        if (record.majorHWVersion === 1) {
          return <CheckCircle htmlColor={green[500]} />;
        }

        if (!satisfies(record.firmwareVersion ?? "0.0.0", ">=2.12 <3.0.0")) {
          return (
            <Tooltip title="Firmware to upgrade">
              <CloudOff htmlColor={red[500]} />
            </Tooltip>
          );
        }

        const deviceCertificateDetails = record.targetCertificate?.crc ? (
          <Tooltip title="Device certificate transfer in progress">
            <Downloading htmlColor={blue[500]} />
          </Tooltip>
        ) : record.currentCertificate?.crc ? (
          <Tooltip
            title={`Device certificate valid until ${dayjs(
              record.currentCertificate.expirationDate,
            ).format("YYYY-MM-DD")}`}
          >
            <DeviceCertificateIcon
              htmlColor={
                dayjs(record.currentCertificate.expirationDate).diff(
                  new Date(),
                  "days",
                ) < 90
                  ? orange[500]
                  : green[500]
              }
            />
          </Tooltip>
        ) : (
          <Tooltip title="Device certificate transfer not initiated">
            <DeviceCertificateIcon htmlColor={red[500]} />
          </Tooltip>
        );

        return (
          <>
            {deviceCertificateDetails}
            <Tooltip
              title={
                record.isCaServerUpToDate
                  ? "CA server up to date"
                  : "CA server needs update"
              }
            >
              <CAServerIcon
                htmlColor={record.isCaServerUpToDate ? green[500] : red[500]}
              />
            </Tooltip>
          </>
        );
      }}
      variant="body1"
      {...props}
    />
  );
}
