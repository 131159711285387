import { Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { EditableListField } from "../../../fields/EditableListField";

export const InstallationAppointmentSettingsForm = () => {
  const { setValue, watch } = useFormContext();
  const { brevoTemplateId, smsText, reminderBrevoTemplateId, reminderSMSText } =
    watch("installationAppointmentSettings") ?? {};

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Installation appointment communication settings
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <EditableListField
          label="Brevo template ID"
          itemLabel={{
            enabled: true,
            label: "Template Name",
            helperText:
              "This name is only used to help selecting the right template",
          }}
          value={brevoTemplateId}
          onChange={(value) =>
            setValue("installationAppointmentSettings.brevoTemplateId", value, {
              shouldDirty: true,
            })
          }
          listId="Installation appointment Brevo template id"
        />
      </Grid>
      <Grid item xs={12}>
        <EditableListField
          label="SMS text"
          itemLabel={{
            enabled: true,
            label: "SMS Name",
            helperText:
              "This text is only used to help selecting the right SMS",
          }}
          value={smsText}
          onChange={(value) =>
            setValue("installationAppointmentSettings.smsText", value, {
              shouldDirty: true,
            })
          }
          listId="Installation appointment SMS text"
          sx={{
            "& .MuiSelect-select": {
              whiteSpace: "normal",
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <EditableListField
          label="Reminder Brevo template ID"
          itemLabel={{
            enabled: true,
            label: "SMS Name",
            helperText:
              "This text is only used to help selecting the right SMS",
          }}
          value={reminderBrevoTemplateId}
          onChange={(value) =>
            setValue(
              "installationAppointmentSettings.reminderBrevoTemplateId",
              value,
              { shouldDirty: true },
            )
          }
          listId="Installation appointment reminder Brevo template id"
        />
      </Grid>
      <Grid item xs={12}>
        <EditableListField
          label="Reminder SMS text"
          itemLabel={{
            enabled: true,
            label: "SMS Name",
            helperText:
              "This text is only used to help selecting the right SMS",
          }}
          value={reminderSMSText}
          onChange={(value) =>
            setValue("installationAppointmentSettings.reminderSMSText", value, {
              shouldDirty: true,
            })
          }
          listId="Installation appointment reminder SMS text"
          sx={{
            "& .MuiSelect-select": {
              whiteSpace: "normal",
            },
          }}
        />
      </Grid>
    </Grid>
  );
};
