import dayjs from "dayjs";
import { useEffect } from "react";
import { useGetManyReference, useRecordContext } from "react-admin";
import { useQueryParam } from "use-query-params";
import { useDateRangeFilter } from "../../../../../filters/useDateRangeFilter";
import type { DataFrame } from "../../../../frames/data/type";

export const useRedirectOnLastData = () => {
  const record = useRecordContext();
  const [startDate] = useQueryParam("startDate");
  const [endDate] = useQueryParam("endDate");
  const { setDateRange } = useDateRangeFilter();
  const { data: dataFrames } = useGetManyReference<DataFrame>(
    "data-frames",
    {
      id: record?.id,
      target: "device_id",
      pagination: { page: 1, perPage: 1 },
      sort: { field: "ts", order: "DESC" },
    },
    { staleTime: 1000 * 60 * 5, enabled: Boolean(record) },
  );

  useEffect(() => {
    if (!startDate && !endDate && dataFrames?.length) {
      const lastDate = new Date(dataFrames[0].timestamp);
      if (dayjs().diff(lastDate, "days") > 5) {
        setDateRange(
          {
            startDate: dayjs(lastDate).subtract(7, "days").toDate(),
            endDate: lastDate,
          },
          "replaceIn",
        );
      }
    }
  }, [startDate, endDate, dataFrames, setDateRange]);
};
