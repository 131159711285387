import { getErrorMessage } from "@elax/utils";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { useState } from "react";
import {
  Datagrid,
  Link,
  Pagination,
  ReferenceManyField,
  useNotify,
  useRecordContext,
} from "react-admin";
import { ActionDialog, ActionDialogButton } from "../../../layout/actions-menu";
import { axiosClient } from "../../../providers/data";
import { alertCodeToFrenchLabel } from "../../alerts/types";
import { communicationFields } from "../../communications/fields";
import { SelectResidenceContact } from "../../residence-contact/fields/SelectResidenceContact";
import type { ResidenceContact } from "../../residence-contact/types";
import type { Incident } from "../types";

export const SendIncidentToLandlordDialog = () => {
  const record = useRecordContext<Incident>();
  const notify = useNotify();
  const [residenceContact, setResidenceContact] =
    useState<ResidenceContact | null>(null);

  const sendEmail = () =>
    axiosClient
      .post(`/incidents/${record?.id}/send-to-landlord`, {
        residenceContactId: residenceContact?.id,
      })
      .then(() => {
        notify(
          "Done! Please check #communication-bailleurs for confirmation or error",
          { type: "success" },
        );
      })
      .catch((error: unknown) => {
        notify(`Error: ${getErrorMessage(error)}`, { type: "error" });
      });

  if (!record) {
    return null;
  }

  return (
    <ActionDialog>
      <DialogTitle>
        Send details of this incident to a residence contact
      </DialogTitle>

      <DialogContent>
        <Divider sx={{ mb: 2 }} />
        <strong>Already sent emails:</strong>
        <ReferenceManyField
          reference="communications"
          target="context.incidentId"
          pagination={<Pagination />}
        >
          <Datagrid bulkActionButtons={false}>
            {communicationFields.date}
            {communicationFields.status}
            {communicationFields.residenceContact}
          </Datagrid>
        </ReferenceManyField>
        <Divider sx={{ mb: 2 }} />
        <Box mb={2}>
          <strong>Device concerned:</strong>{" "}
          <Link to={`/devices/${record.device?.id}/show`}>
            {record.device?.name}
          </Link>
        </Box>
        <Box mb={4}>
          <strong>Incident type:</strong>{" "}
          {alertCodeToFrenchLabel[record.type] ?? "Autre"}
        </Box>
        <Box mb={2}>
          <SelectResidenceContact
            filter={{
              deviceId: record.deviceId,
              email: { $exists: true, $ne: "" },
            }}
            onChange={setResidenceContact}
          />
        </Box>
        {residenceContact && (
          <Box mb={2}>
            <strong>Recipient:</strong>
            <br />
            {residenceContact.firstName + " " + residenceContact.lastName}
            <br />
            {residenceContact.type}{" "}
            {residenceContact.jobTitle ? `(${residenceContact.jobTitle})` : ""}
            <br />
            {residenceContact.email}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <ActionDialogButton>Cancel</ActionDialogButton>
        <ActionDialogButton
          variant="contained"
          onClick={async (_, onClose) => {
            await sendEmail();
            onClose();
          }}
          disabled={!residenceContact}
        >
          Send email
        </ActionDialogButton>
      </DialogActions>
    </ActionDialog>
  );
};
