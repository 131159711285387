import DemandResponseIcon from "@mui/icons-material/TrendingDown";
import dayjs from "dayjs";
import { DemandResponseCreate, DemandResponseEdit } from "./edit/Edit";
import { DemandResponseShow } from "./show";
import { DemandResponseType } from "./types";
import type { DemandResponse } from "./types";

export const demandResponseResource = {
  icon: DemandResponseIcon,
  edit: DemandResponseEdit,
  show: DemandResponseShow,
  create: DemandResponseCreate,
  options: { label: "Effacements" },
  recordRepresentation: (record: DemandResponse) =>
    `${
      record.type === DemandResponseType.DOWN ? "Effacement" : "Report"
    } du ${dayjs(record.startDate).format("DD/MM/YYYY HH:mm")}`,
};

export { default as DemandResponseIcon } from "@mui/icons-material/TrendingDown";
