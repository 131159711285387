import { Box, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { range } from "lodash";

type OffPeakHoursProps = {
  offPeakHours: {
    TimeStart: {
      Time: string;
    };
    TimeEnd: {
      Time: string;
    };
  }[];
};
export const OffPeakHours = ({ offPeakHours }: OffPeakHoursProps) => {
  const getCurrentOffPeakHours = (hour: number) =>
    offPeakHours.find(({ TimeStart, TimeEnd }) => {
      const start = new Date(TimeStart.Time);
      const end = new Date(TimeEnd.Time);

      const startHour = start.getHours();
      const endHour = end.getHours();
      if (endHour < startHour) {
        return hour <= endHour || hour >= startHour;
      }

      return hour >= startHour && hour <= endHour;
    });
  return (
    <Box
      sx={{
        display: "flex",
        borderRadius: 1,
        overflow: "hidden",
        width: "100%",
      }}
    >
      {range(0, 24).map((i) => {
        const currentOffPeakHours = getCurrentOffPeakHours(i);

        const title = currentOffPeakHours
          ? `${dayjs(currentOffPeakHours.TimeStart.Time).format(
              "HH:mm:ss",
            )}  → ${dayjs(currentOffPeakHours.TimeEnd.Time).format("HH:mm:ss")}`
          : `${i}h → ${i + 1}h`;

        return (
          <Tooltip title={title} key={i}>
            <Box
              sx={{
                flex: "1 1 0px",
                backgroundColor: currentOffPeakHours ? "#03a9f4" : "#9e9e9e",
                opacity: 0.5,
                borderLeft: "solid 1px #000000",
                height: 20,
                "&:first-of-type": {
                  borderLeft: "none",
                },
              }}
            />
          </Tooltip>
        );
      })}
    </Box>
  );
};
