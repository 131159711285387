import { Datagrid, List, ListActions } from "react-admin";
import { MarkdownPanel } from "../../layout/MarkdownPanel";
import { residenceContactFields } from "./fields";

export const ResidenceContactList = () => {
  return (
    <List perPage={200} actions={<ListActions hasCreate />}>
      <Datagrid
        bulkActionButtons={false}
        expand={<MarkdownPanel source="notes" />}
        isRowExpandable={(record) => record.notes}
      >
        {residenceContactFields.organization}
        {residenceContactFields.role}
        {residenceContactFields.firstName}
        {residenceContactFields.lastName}
        {residenceContactFields.company}
        {residenceContactFields.email}
        {residenceContactFields.phone}
        {residenceContactFields.residences}
        {residenceContactFields.createdAt}
        {residenceContactFields.updatedAt}
      </Datagrid>
    </List>
  );
};
