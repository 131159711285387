import { ReferenceInput, SelectInput } from "react-admin";
import { useWatch } from "react-hook-form";
import { InterventionStatus, InterventionType } from "../types";

export const ReplacedDeviceInput = () => {
  const type = useWatch({ name: "type" });
  const status = useWatch({ name: "status" });
  const housingId = useWatch({ name: "housingId" });

  if (
    type === InterventionType.REPLACEMENT &&
    status === InterventionStatus.SUCCESS
  ) {
    return (
      <ReferenceInput
        reference="devices"
        source="replacedDeviceId"
        fullWidth
        perPage={1000}
        isRequired
        filter={{
          housingId,
        }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    );
  }

  return null;
};
