import {
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  Pagination,
  ReferenceManyField,
  TextField,
} from "react-admin";
import { RoundedNumberField } from "../../../../fields/RoundedNumberField";
import { CancelledField } from "../../../demand-response/fields/CancelledField";
import type { DemandResponsePool } from "../../../demand-response-pools/types";

export const BalancingEntityPoolsTab = ({
  filter,
}: {
  filter?: Record<keyof DemandResponsePool, any>;
}) => {
  return (
    <ReferenceManyField
      reference="demand-response-pools"
      target="entity"
      filter={filter}
      sort={{ field: "startDate", order: "DESC" }}
      pagination={<Pagination />}
      label={false}
    >
      <Datagrid bulkActionButtons={false} expand={<ExpandPool />}>
        <DateField
          source="startDate"
          label="Start date"
          showTime={true}
          variant="body1"
        />
        <DateField
          source="endDate"
          label="End date"
          showTime={true}
          variant="body1"
        />
        <FunctionField<DemandResponsePool>
          label="# PDLs"
          variant="body1"
          render={(record) => record.pdls.length ?? 0}
        />
        <TextField label="# Offres" source="balancingBidStats.count" />
        <TextField source="method" label="Méthode" />
      </Datagrid>
    </ReferenceManyField>
  );
};

const ExpandPool = () => {
  return (
    <ReferenceManyField
      reference="balancing-bids"
      target="poolId"
      pagination={<Pagination />}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField
          source="formattedDate"
          label="Day"
          showTime={false}
          variant="body1"
        />
        <NumberField label="Activations" source="activationCount" />
        <CancelledField label="Cancelled?" />
        <RoundedNumberField
          label="Max energy in MWh"
          source="maxEnergyInMWh"
          unit="MW"
        />
        <RoundedNumberField
          label="Min duration"
          source="minDurationInMinutes"
          unit="min"
        />
        <RoundedNumberField
          label="Max duration"
          source="maxDurationInMinutes"
          unit="min"
        />
      </Datagrid>
    </ReferenceManyField>
  );
};
