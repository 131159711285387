import { IncidentStatus } from "@elax/business-types";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import EditIcon from "@mui/icons-material/Edit";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Alert,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import {
  DateField,
  Link,
  RecordContext,
  ReferenceManyField,
  WithListContext,
  WithRecord,
} from "react-admin";
import { FunctionList } from "../../../../../display/FunctionList";
import { useDateRangeFilter } from "../../../../../filters/useDateRangeFilter";
import { DialogActionIconButton } from "../../../../../layout/actions-menu/ActionDialog";
import { ActionMenuStateProvider } from "../../../../../layout/actions-menu/context";
import { alertSeverities } from "../../../../alerts";
import { codeToLabel } from "../../../../alerts/fields/AlertCodeField";
import type { AlertHistory } from "../../../../alerts/types";
import { EditIncidentDialog } from "../../../../incidents/edit/EditIncident";
import { IncidentHistory } from "../../../../incidents/fields/Events";
import { HasIncidentCommunicationSent } from "../../../../incidents/fields/HasIncidentCommunicationSent";
import { IncidentStatusField } from "../../../../incidents/fields/IncidentStatusField";
import { ResolveIncidentActionsMenu } from "../../../../incidents/fields/ResolveIncidentActionsMenu";
import { incidentActorLabels } from "../../../../incidents/types";
import type { Incident } from "../../../../incidents/types";
import { UserField } from "../../../../users/fields/UserField";
import InfoList from "../InfoList";

const isIncidentUnresolved = (incident: Incident) =>
  incident.status === IncidentStatus.OPENED;
const isIncidentResolved = (incident: Incident) =>
  !isIncidentUnresolved(incident);

export default function IncidentsInfoList() {
  const { setDateRange } = useDateRangeFilter();
  const centerGraphOnAlert = (alert: AlertHistory) => {
    setDateRange({
      startDate: dayjs(alert.timeStart).subtract(1, "day").toDate(),
      endDate: dayjs(alert.timeStart).add(1, "day").toDate(),
    });
  };

  return (
    <ReferenceManyField
      reference="incidents"
      target="deviceId"
      sort={{ field: "createdAt", order: "DESC" }}
      perPage={1000}
    >
      <WithListContext<Incident>
        render={({ data }) => {
          if (!data || data.length === 0) {
            return null;
          }
          const resolvedIncidents = data.filter((incident) =>
            isIncidentResolved(incident),
          );
          return (
            <InfoList label="Incidents">
              {data.filter(isIncidentUnresolved).map((incident) => (
                <IncidentItem incident={incident} key={incident.id} />
              ))}

              <InfoList.Collapsible>
                {resolvedIncidents.map((incident) => (
                  <IncidentItem incident={incident} key={incident.id} />
                ))}
                <ReferenceManyField
                  reference="alerts"
                  target="deviceId"
                  filter={{ timeEnd: { $exists: false }, includeMuted: true }}
                  perPage={1000}
                >
                  <FunctionList>
                    <WithRecord<AlertHistory>
                      render={(record) => (
                        <Tooltip
                          title={
                            <>
                              <Typography variant="body2" display="block">
                                {record.message || ""}
                              </Typography>
                              <Typography variant="body2" display="block">
                                {record.comment || "No comment"}
                              </Typography>
                              <DateField
                                record={record}
                                source="timeStart"
                                showTime
                              />
                            </>
                          }
                          placement="top"
                          arrow
                        >
                          <Alert
                            severity={alertSeverities[record.code] || "warning"}
                            sx={{ marginTop: 1, marginBottom: 1 }}
                            action={
                              <>
                                <Link
                                  to={`/alerts/${record.id}`}
                                  color="inherit"
                                >
                                  <Tooltip title="Comment">
                                    <IconButton size="small" color="inherit">
                                      <EditIcon fontSize="inherit" />
                                    </IconButton>
                                  </Tooltip>
                                </Link>

                                <Tooltip title="Show on graph">
                                  <IconButton
                                    onClick={() => centerGraphOnAlert(record)}
                                    size="small"
                                    color="inherit"
                                  >
                                    <VisibilityIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                          >
                            {codeToLabel[record.code]?.label ?? record.code}
                          </Alert>
                        </Tooltip>
                      )}
                    />
                  </FunctionList>
                </ReferenceManyField>
              </InfoList.Collapsible>
            </InfoList>
          );
        }}
      />
    </ReferenceManyField>
  );
}

const IncidentItem = ({ incident }: { incident: Incident }) => {
  const lastEvent = incident.events.at(-1);
  return (
    <RecordContext.Provider value={incident}>
      <Box
        sx={{
          color: codeToLabel[incident.type]?.color["400"],
          marginTop: 1,
          marginBottom: 1,
          padding: 1,
          border: `solid 1px ${
            codeToLabel[incident.type]?.color["400"] ?? "black"
          }`,
          borderRadius: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box flexGrow={1} display="flex" flexDirection="column" gap={0.5}>
          <Typography
            variant="body2"
            fontWeight="bold"
            alignItems="center"
            display="flex"
            gap={0.5}
          >
            <IncidentStatusField />
            {codeToLabel[incident.type]?.label ?? incident.type}
            <ActionMenuStateProvider>
              <DialogActionIconButton
                dialog={<EditIncidentDialog />}
                sx={{ padding: 0 }}
              >
                <EditIcon sx={{ marginLeft: 0.5, fontSize: 16 }} />
              </DialogActionIconButton>
            </ActionMenuStateProvider>
            <HasIncidentCommunicationSent />
          </Typography>
          <Stack direction="row" gap={0.5}>
            <AddCircleOutlineIcon fontSize="small" />
            <Typography variant="body2">
              {`${dayjs(incident.createdAt).format("DD/MM/YY HH:mm")} (${dayjs(
                incident.createdAt,
              ).fromNow()})`}
            </Typography>
          </Stack>
          <Stack direction="row" gap={0.5}>
            <AssignmentIndIcon fontSize="small" />
            <Typography variant="body2">
              {incident.assignedTo &&
                incidentActorLabels[incident.assignedTo].label}{" "}
              {lastEvent?.action || lastEvent?.comment}
            </Typography>
          </Stack>
          {lastEvent?.status === IncidentStatus.CLOSED && (
            <Stack direction="row" gap={0.5}>
              <TaskAltIcon fontSize="small" />
              <Typography variant="body2">
                {`${dayjs(lastEvent.datetime).format(
                  "DD/MM/YY HH:mm",
                )} (in ${dayjs
                  .duration(dayjs(lastEvent.datetime).diff(incident.createdAt))
                  .humanize()})`}
                <UserField
                  userId={lastEvent.userId}
                  emptyText="AUTO"
                  variant="body2"
                />
              </Typography>
            </Stack>
          )}
        </Box>
        <Box>
          <IncidentHistory size="small" />
          {isIncidentUnresolved(incident) && <ResolveIncidentActionsMenu />}
        </Box>
      </Box>
    </RecordContext.Provider>
  );
};
