import { Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useStore } from "react-admin";
import { createStateContext } from "react-use";
import InfoList from "../InfoList";

export default function FavoritesInfoList() {
  const hasFavorites = useHasFavorites();
  const [, setReady] = useFavoritesReady();

  useEffect(() => {
    setReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- to fix
  }, []);

  return (
    <InfoList label="Favorites" id={FAVORITE_INFO_LIST_ID}>
      {hasFavorites ? null : (
        <Typography variant="body2">
          To add favorites, click on the star near each field
        </Typography>
      )}
    </InfoList>
  );
}

export const [useFavoritesReady, FavoritesReadyProvider] =
  createStateContext(false);

export const FAVORITE_INFO_LIST_ID = "device-favorites";

export const useFavorite = (key: string) => {
  const [favorites, setFavorites] = useStore<Record<string, boolean>>(
    `device.favorites`,
    {},
  );

  const isFavorite = Boolean(favorites[key]);

  const setFavorite = useCallback(
    (valueGetter: (prevValue: boolean | undefined) => boolean) => {
      setFavorites((favorites) => ({
        ...favorites,
        [key]: valueGetter(favorites[key]),
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- to fix
    [setFavorites],
  );

  return [isFavorite, setFavorite] as const;
};

const useHasFavorites = () => {
  const [favorites] = useStore<Record<string, boolean>>(`device.favorites`, {});

  return Object.values(favorites).filter(Boolean).length > 0;
};
