import PowerIcon from "@mui/icons-material/Bolt";
import { Box, Grid, Typography } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { useState } from "react";
import { FunctionField, useRecordContext } from "react-admin";
import { useDemandResponseEntityDetails } from "../../../../providers/data-hooks/useDemandResponseEntityDetails";
import { ConsumptionProfileGraph } from "../../fields/ConsumptionProfile";
import { DemandResponseEntityMechanism } from "../../type";
import type { DemandResponseEntity } from "../../type";
import "dayjs/locale/fr";
import { BalancingEntityPoolsTab } from "./BalancingEntityPoolsTab";
import { DemandResponseEntityPoolsTab } from "./DemandResponseEntityPoolsTab";

export const DemandResponseEntitySummaryTab = () => {
  const record = useRecordContext<DemandResponseEntity>();
  const { data } = useDemandResponseEntityDetails(record?.id);

  const [startDate, setStartDate] = useState<Dayjs | null>(() =>
    dayjs().subtract(7, "days"),
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(() => dayjs());

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FunctionField
          render={(record: any) =>
            record && (
              <Box display="flex" alignItems="center">
                <PowerIcon sx={{ marginRight: 1 }} />
                <Typography>
                  Maximum Capacity: {record.maxCapacityInMW}MW
                </Typography>
              </Box>
            )
          }
        />
        <FunctionField
          render={(record: any) =>
            record && (
              <Box display="flex" alignItems="center">
                <PowerIcon sx={{ marginRight: 1 }} />
                <Typography>
                  Heaters Power:{" ~"}
                  {data ? data.totalCapacityInMW.toFixed(3) : "..."}MW
                </Typography>
              </Box>
            )
          }
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" mt={2}>
          Current pool
        </Typography>
        {record?.mechanism === DemandResponseEntityMechanism.BALANCING ? (
          <BalancingEntityPoolsTab
            filter={{
              startDate: { $lte: dayjs().format() },
              endDate: { $gte: dayjs().format() },
            }}
          />
        ) : (
          <DemandResponseEntityPoolsTab
            filter={{
              startDate: { $lte: dayjs().format() },
              endDate: { $gte: dayjs().format() },
            }}
          />
        )}
      </Grid>

      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <Grid sm={6} item>
          <DesktopDatePicker
            slotProps={{ textField: { sx: { mr: 2 } } }}
            value={startDate}
            onChange={setStartDate}
            label="Start Date"
            maxDate={dayjs()}
          />
        </Grid>
        <Grid sm={6} item>
          <DesktopDatePicker
            value={endDate}
            onChange={setEndDate}
            label="End Date"
            minDate={startDate || undefined}
            maxDate={dayjs()}
          />
        </Grid>
      </LocalizationProvider>

      <Grid mt={2} sm={12} item>
        <ConsumptionProfileGraph
          startDate={startDate?.toDate()}
          endDate={endDate?.toDate()}
        />
      </Grid>
    </Grid>
  );
};
