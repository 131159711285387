import type { ComponentProps, ReactNode } from "react";
import { OptimizedReferenceOneField } from "../../../fields/OptimizedReferenceOneField";
import { InterventionType } from "../../intervention/types";

export const HousingLastInstallationField = (
  props: Partial<ComponentProps<typeof OptimizedReferenceOneField>> & {
    children: ReactNode;
  },
) => (
  <OptimizedReferenceOneField
    reference="interventions"
    target="housingId"
    sortField="datetime"
    sortOrder="desc"
    label="Latest Intervention"
    sortable={false}
    link="show"
    fields={["status", "visitStatus", "datetime", "plannedSlot"]}
    filter={{ type: InterventionType.INSTALLATION }}
    {...props}
  />
);
