import Fullscreen from "@mui/icons-material/Fullscreen";
import FullscreenExit from "@mui/icons-material/FullscreenExit";
import { Alert, Box, IconButton, Typography } from "@mui/material";
import { canAccess } from "@react-admin/ra-rbac";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import type { TabProps } from "react-admin";
import {
  ReferenceManyField,
  ReferenceOneField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  WithRecord,
  usePermissions,
  useRecordContext,
  useSidebarState,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-use";
import type { Except } from "type-fest";
import { FunctionList } from "../../../display/FunctionList";
import { PageTitle } from "../../../layout/PageTitle";
import { NameField } from "../../end-users/fields/NameField";
import { DeviceStatusField } from "../fields/Status";
import type { Device } from "../types";
import { RedirectIMEI } from "./RedirectIMEI";
import { DeviceShowActions } from "./actions";
import Aside from "./aside/Aside";
import { DataTab } from "./tabs/data";
import { useRedirectOnLastData } from "./tabs/data/useRedirectOnLastData";
import { EnergyTab } from "./tabs/energy";
import { History } from "./tabs/history";
import { Pilot } from "./tabs/pilot";
import { SummaryTab } from "./tabs/summary/index";

function DeviceShowTitle() {
  const device = useRecordContext<Device>();
  if (!device) return null;
  return (
    <Typography variant="h5" textAlign="center" marginTop={1} marginBottom={2}>
      {device.IMEI}
      <ReferenceManyField
        reference="end-users"
        source="housingId"
        target="housingIds"
      >
        <FunctionList>
          <>
            {" "}
            - <NameField variant="h5" />
          </>
        </FunctionList>
      </ReferenceManyField>
    </Typography>
  );
}

export const ShowDevice = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showAside, setShowAside] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useSidebarState();
  const [sidebarOpenStateBeforeExpand, setSidebarOpenStateBeforeExpand] =
    useState(sidebarOpen);

  useEffect(() => {
    if (location.href?.replace(/\/$/, "").endsWith("/show")) {
      navigate(`charts`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- to fix
  }, [location]);

  return (
    <RedirectIMEI>
      <Show
        actions={<DeviceShowActions />}
        aside={showAside ? <Aside /> : undefined}
        sx={{
          marginBottom: 2,
          "& .RaShow-card": { flex: "1 1 0%", marginTop: 2 },
        }}
      >
        <PageTitle />
        <Box sx={{ paddingX: 2 }}>
          <Box sx={{ position: "relative" }}>
            <Box sx={{ position: "absolute", right: 0, top: 0 }}>
              <IconButton
                size="small"
                onClick={() =>
                  setShowAside((isShowed) => {
                    if (isShowed) {
                      // save current sidebar open state
                      setSidebarOpenStateBeforeExpand(sidebarOpen);
                      // also close sidebar
                      setSidebarOpen(false);
                    } else {
                      // back to previous sidebar open state
                      setSidebarOpen(sidebarOpenStateBeforeExpand);
                    }
                    return !isShowed;
                  })
                }
              >
                {showAside ? (
                  <Fullscreen sx={{ transform: "rotate(90deg)" }} />
                ) : (
                  <FullscreenExit sx={{ transform: "rotate(90deg)" }} />
                )}
              </IconButton>
            </Box>
            <DeviceShowTitle />
          </Box>
        </Box>
        <WithRecord
          render={(device) => (
            <>
              {device?.notes ? (
                <Typography variant="h4" textAlign="center">
                  {device.notes}
                </Typography>
              ) : null}

              {device?.replacedByDeviceId ? (
                <ReferenceOneField
                  reference="devices"
                  source="replacedByDeviceId"
                  target="id"
                  link="show"
                >
                  <Alert severity="info" sx={{ margin: 1 }}>
                    <Typography fontStyle="italic">
                      This device was uninstalled and replaced by:
                    </Typography>
                    <Box display="flex" gap={1} alignItems="center">
                      <SimpleShowLayout>
                        <TextField source="IMEI" label="IMEI" />
                        <TextField source="name" />
                        <DeviceStatusField label="Status" />
                      </SimpleShowLayout>
                    </Box>
                  </Alert>
                </ReferenceOneField>
              ) : null}
            </>
          )}
        />
        <Tabs />
      </Show>
    </RedirectIMEI>
  );
};

const tabs: {
  display: (record: any) => boolean;
  path?: string;
  Tab: (props: Except<TabProps, "label" | "children">) => ReactNode;
}[] = [
  {
    display: () => true,
    path: "charts",
    Tab: (props) => (
      <Tab {...props} label="Data">
        <DataTab />
      </Tab>
    ),
  },
  {
    display: () => true,
    path: "history",
    Tab: (props) => (
      <Tab {...props} label="history">
        <History />
      </Tab>
    ),
  },
  {
    display: () => true,
    path: "pilot",
    Tab: (props) => (
      <Tab {...props} label="Pilot">
        <Pilot />
      </Tab>
    ),
  },
  {
    display: (record) => record.majorHWVersion !== 1,
    path: "energy",
    Tab: (props) => (
      <Tab {...props} label="Energy">
        <EnergyTab />
      </Tab>
    ),
  },
  {
    display: () => true,
    path: "summary",
    Tab: (props) => (
      <Tab {...props} label="summary">
        <SummaryTab />
      </Tab>
    ),
  },
];

const Tabs = (props: any) => {
  const record = useRecordContext();
  const { permissions } = usePermissions();
  useRedirectOnLastData();

  if (!record) {
    return null;
  }

  return (
    <TabbedShowLayout {...props}>
      {tabs
        .filter(
          (tab) =>
            tab.display(record) &&
            canAccess({
              permissions,
              action: "read",
              resource: `devices.tab.${tab.path || "summary"}`,
            }),
        )
        .map((tab, index) => (
          <tab.Tab key={index} path={tab.path} />
        ))}
    </TabbedShowLayout>
  );
};
