import { Mode } from "@elax/business-types";

export type DeviceConfig = {
  id: string;
  createdAt: string;
  mode: Mode;
  notes?: string;
  parameters: any;
};

export const MODES_COMPATIBLE_WITH_PERSONAS = [Mode.SMART, Mode.SMART_ENERGY];
