import { EnumField } from "../../../fields/EnumField";
import { heatingStates } from "../../../layout/dashboard/widgets/HeaterState";

const heatingStateColors = Object.fromEntries(
  heatingStates.map((state) => [state.selector, state.color]),
);

export const heatingStateLabels = Object.fromEntries(
  heatingStates.map((state) => [state.selector, state.label]),
);

export default function HeatingStateField({ label }: { label?: string }) {
  return (
    <EnumField
      label={label || "Heating state"}
      source="state"
      colors={heatingStateColors}
      labels={heatingStateLabels}
    />
  );
}
