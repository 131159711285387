import { AutocompleteInput, ReferenceInput, SelectInput } from "react-admin";
import { DateRangeFilter } from "../../filters/DateRange";
import { serializeEndUserName } from "../end-users/fields/NameField";
import type { EndUser } from "../end-users/type";
import {
  communicationMediumConfigs,
  communicationStatusConfigs,
  communicationTopicConfigs,
} from "./type";

export const communicationFilters = {
  date: <DateRangeFilter source="date" label="Date" />,
  status: (
    <SelectInput
      source="status"
      label="Status"
      choices={Object.entries(communicationStatusConfigs).map(
        ([key, value]) => ({
          id: key,
          name: value.label,
        }),
      )}
      fullWidth
    />
  ),
  medium: (
    <SelectInput
      source="medium"
      label="Medium"
      choices={Object.entries(communicationMediumConfigs).map(
        ([key, value]) => ({
          id: key,
          name: value.label,
        }),
      )}
      fullWidth
    />
  ),
  topic: (
    <SelectInput
      source="topic"
      label="Topic"
      choices={Object.entries(communicationTopicConfigs).map(
        ([key, value]) => ({
          id: key,
          name: value.label,
        }),
      )}
      fullWidth
    />
  ),
  housing: (
    <ReferenceInput
      reference="housings"
      label="Housing"
      source="housingId"
      perPage={100}
    >
      <AutocompleteInput optionText="unitId" />
    </ReferenceInput>
  ),
  endUser: (
    <ReferenceInput
      reference="end-users"
      label="Resident"
      source="endUserId"
      perPage={100}
    >
      <AutocompleteInput<EndUser>
        inputText={(option) => serializeEndUserName(option)}
        optionText={(option) => serializeEndUserName(option)}
      />
    </ReferenceInput>
  ),
  project: (
    <ReferenceInput
      reference="projects"
      label="Project"
      source="projectId"
      perPage={100}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  ),
  organization: (
    <ReferenceInput
      reference="organizations"
      label="Organization"
      source="organizationId"
      perPage={100}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  ),
  maintenanceProvider: (
    <ReferenceInput
      reference="maintenance-providers"
      label="Maintenance Provider"
      source="maintenanceProviderId"
      perPage={100}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  ),
};
