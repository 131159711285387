import AddIcon from "@mui/icons-material/Add";
import MoreIcon from "@mui/icons-material/MoreHoriz";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { CreateDialog, FormDialogContext } from "@react-admin/ra-form-layout";
import type { CreateDialogProps } from "@react-admin/ra-form-layout";
import { useCallback, useMemo, useState } from "react";
import { useCloseMenu } from "./context";

export const CreateAction = (props: CreateDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeMenu = useCloseMenu();
  const open = useCallback(() => {
    setIsOpen(true);
  }, []);
  const close = useCallback(() => {
    setIsOpen(false);
    closeMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- to fix
  }, []);

  const contextValue = useMemo(
    () => ({
      isOpen,
      open,
      close,
    }),
    [close, isOpen, open],
  );

  return (
    <>
      <FormDialogContext.Provider value={contextValue}>
        <MenuItem
          onClick={(e) => {
            open();
            e.stopPropagation();
          }}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText>Add</ListItemText>
          <MoreIcon color="action" sx={{ marginLeft: 3 }} />
        </MenuItem>
        <CreateDialog {...props} />
      </FormDialogContext.Provider>
    </>
  );
};
