import { useRecordContext } from "react-admin";
import { FieldItem } from "../../../display/FieldItem";
import { EndUserIcon } from "../../end-users";
import { HousingIcon } from "../../housing";
import { InterventionIcon } from "../../intervention";
import { MaintenanceProviderIcon } from "../../maintenance-provider";
import { ClientIcon } from "../../organization";
import { ProjectIcon } from "../../project";
import type { Communication } from "../type";
import { communicationFields } from ".";

export function CommunicationContext() {
  const communication = useRecordContext<Communication>();

  return (
    <>
      {[
        {
          key: "organizationId",
          label: "Organization",
          Icon: ClientIcon,
          field: communicationFields.organization,
        },
        {
          key: "projectId",
          label: "Project",
          Icon: ProjectIcon,
          field: communicationFields.project,
        },
        {
          key: "housingId",
          label: "Housing",
          Icon: HousingIcon,
          field: communicationFields.housing,
        },
        {
          key: "endUserId",
          label: "End User",
          Icon: EndUserIcon,
          field: communicationFields.resident,
        },
        {
          key: "maintenanceProviderId",
          label: "Maintenance Provider",
          Icon: MaintenanceProviderIcon,
          field: communicationFields.maintenanceProvider,
        },
        {
          key: "interventionId",
          label: "Intervention",
          Icon: InterventionIcon,
          field: communicationFields.intervention,
        },
      ]
        .filter(({ key }) =>
          Object.keys(communication?.context ?? {}).includes(key),
        )
        .map(({ field, label, Icon }) => (
          <FieldItem label={label} icon={<Icon />} key={label}>
            {field}
          </FieldItem>
        ))}
    </>
  );
}
