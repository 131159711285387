import { useRecordContext } from "react-admin";
import type { Device } from "../../../../types";
import InfoList from "../../InfoList";
import { ComfortSlider } from "./ComfortSlider";
import { EditComfortDialog } from "./EditComfortDialog";
import { useComfortData } from "./useComfortData";

export const ComfortFields = () => {
  const record = useRecordContext<Device>();

  const { data: comfortConfig, error } = useComfortData(record?.id);

  return (
    <>
      {[
        {
          label: "Day Comfort",
          config: comfortConfig?.dayComfort,
          value: comfortConfig?.currentConfig.deltaDayTemperatureInC,
          description:
            "Ce paramètre augmente la température de boost par rapport à l'algorithme par défaut. Il s'agit de la valeur comfigurable dans l'app résident",
        },
        {
          label: "Night Comfort",
          config: comfortConfig?.nightComfort,
          value: comfortConfig?.currentConfig.deltaNightTemperatureInC,
          description:
            "Ce paramètre n'est à utiliser qu'exceptionnellement pour augmenter la température de base. Privilégier la confort de jour (température de boost) pour la majorité des cas (HC/HP & base).",
        },
      ].map(({ label, config, value, description }) =>
        config || error ? (
          <InfoList.Item
            label={label}
            editDialog={
              config ? (
                <EditComfortDialog
                  comfortType="day"
                  minValue={config.minSelectableTemperatureInC}
                  maxValue={config.maxSelectableTemperatureInC}
                  recommendedValue={config.recommendedTemperatureInC}
                  initialValue={value}
                  description={description}
                />
              ) : undefined
            }
          >
            {config ? (
              <ComfortSlider
                minValue={config.minSelectableTemperatureInC}
                maxValue={config.maxSelectableTemperatureInC}
                recommendedValue={config.recommendedTemperatureInC}
                deltaValue={value ?? 0}
              />
            ) : error ? (
              <>{error.message}</>
            ) : null}
          </InfoList.Item>
        ) : null,
      )}
    </>
  );
};
