import InterventionIcon from "@mui/icons-material/Build";
import dayjs from "dayjs";
import { InterventionCreate, InterventionEdit } from "./Edit";
import { InterventionList } from "./List";
import { InterventionShow } from "./Show";
import type { Intervention } from "./types";

export const interventionResource = {
  icon: InterventionIcon,
  list: InterventionList,
  show: InterventionShow,
  edit: InterventionEdit,
  create: InterventionCreate,
  recordRepresentation: (record: Intervention) =>
    `🛠️ ${record.type} ${record.status} ${
      record.datetime ? dayjs(record.datetime).format("DD/MM/YYYY") : ""
    }`,
};

export { default as InterventionIcon } from "@mui/icons-material/Build";
