import { Paper } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { sumBy } from "lodash";
import { useRedirect } from "react-admin";
import { dataProvider } from "../../../providers/data";
import { Chart } from "../../Chart";

type DeviceStatsProps = {
  filter: any;
};

const deviceStates = [
  {
    state: "OPERATIONAL",
    label: "Opérationnel",
    description: "Logement équipé d’un boitier en bon état de fonctionnement.",
    action: "",
    color: "#4caf50",
  },
  {
    state: "DEACTIVATED",
    label: "Mode Intelligent désactivé",
    description:
      "Logement équipé d’un boitier dont le mode Intelligent est désactivé.",
    action:
      "Contact de l’utilisateur pour  repasser le boitier en mode Intelligent.",
    color: "#607D8B",
  },
  {
    state: "LIGHT_BREAKDOWN",
    label: "Panne légère",
    description:
      "Logement équipé d’un boitier actuellement victime d’une panne n’impactant pas le confort de l’utilisateur.",
    action: "Intervention d’un technicien Elax à J+10",
    color: "#ff9800",
  },
  {
    state: "CRITICAL_BREAKDOWN",
    label: "Panne critique",
    description:
      "Logement équipé d’un boitier actuellement victime d’une panne pouvant impacter le confort de l’utilisateur.",
    action: "Intervention d’un technicien Elax à J+3",
    color: "#f44336",
  },
  {
    state: "COMMUNICATION_LOST",
    label: "Perte de communication",
    description:
      "Logement équipé d’un boitier qui ne communique plus depuis plus de 15 jours sans détection de coupure électrique préalable.",
    action:
      "Contact de l’utilisateur pour s’assurer du maintien du confort et comprendre la raison (boitier défaillant, problème de réseau telecom, départ temporaire, logement vacant…).",
    color: "#00bcd4",
  },
  {
    state: "POWER_LOST",
    label: "Perte d’alimentation",
    description:
      "Logement équipé d’un boitier qui ne communique plus depuis plus de 15 jours suite à une coupure d’alimentation électrique.",
    action:
      "Contact de l’utilisateur pour s’assurer du maintien du confort et comprendre la raison (problème de l’installation électrique, départ temporaire, logement vacant…).",
    color: "#2196f3",
  },
  {
    state: "OUT_OF_ORDER",
    label: "Démonté",
    description:
      "Logement préalablement équipé mais dont le boitier a été démonté par Elax.",
    action: "",
    color: "#9e9e9e",
  },
];

export const DevicesState = ({ filter }: DeviceStatsProps) => {
  const { data } = useQuery({
    queryFn: () => dataProvider.getDevicesState(filter),
    queryKey: [`devices/state`, filter],
  });
  const redirect = useRedirect();

  const total = sumBy(deviceStates, ({ state }) => data?.data[state] ?? 0);

  return (
    <Paper elevation={1}>
      <Chart
        isLoading={!data}
        options={{
          title: {
            text: "État des boîtiers Elax",
          },
          subtitle: {
            text: data && `D'après ${total} logements et boîtiers`,
          },
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: true,
                distance: -35,
                formatter() {
                  return Math.round((this as any).percentage).toFixed(0) + "%";
                },
              },
              showInLegend: true,
              startAngle: -90,
              endAngle: 90,
              center: ["50%", "75%"],
              size: "110%",
            },
            series: {
              events: {
                click: (event) => {
                  redirect(
                    () =>
                      `devices?filter=${JSON.stringify({
                        state: (event.point as any).custom.state,
                      })}`,
                  );
                },
              },
            },
          },
          series: data && [
            {
              type: "pie",
              name: "Boîtier Elax",
              innerSize: "50%",
              data: deviceStates
                .map((series) => ({
                  name: series.label,
                  color: series.color,
                  y: data.data[series.state] ?? 0,
                  custom: {
                    state: series.state,
                  },
                }))
                .filter((data) => data.y > 0),
            },
          ],
        }}
      />
    </Paper>
  );
};
