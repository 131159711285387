import { AutocompleteInput, ReferenceInput } from "react-admin";
import { useWatch } from "react-hook-form";
import { InterventionStatus } from "../types";

export const DeviceInput = () => {
  const status = useWatch({ name: "status" });

  if (status !== InterventionStatus.SUCCESS) {
    return null;
  }

  return (
    <ReferenceInput
      reference="devices"
      source="deviceId"
      fullWidth
      perPage={1000}
      isRequired
    >
      <AutocompleteInput label="Device" optionText="name" fullWidth />
    </ReferenceInput>
  );
};
