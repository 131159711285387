import ConsumptionIcon from "@mui/icons-material/ElectricBolt";
import { ActionMenuItem } from "../layout/actions-menu";
import { dataProvider } from "../providers/data";
import type { DevicesFilter } from "../resources/devices/types";
import { useExport } from "./useExport";

type ConsumptionExportProps = {
  filter?: DevicesFilter;
};

export const ConsumptionExport = ({ filter }: ConsumptionExportProps) => {
  const { mutate } = useExport(() =>
    dataProvider.generateConsumptionReport(filter),
  );

  return (
    <ActionMenuItem
      label="Export Consumption"
      icon={<ConsumptionIcon />}
      onClick={mutate}
    />
  );
};
