import {
  Datagrid,
  DateField,
  ReferenceArrayField,
  TextField,
  useRecordContext,
} from "react-admin";
import { getDocumentURL } from "../../documents/documentsHelpers";
import { DocumentLinkField } from "../../documents/fields/DocumentLinkField";
import type { Document } from "../../documents/types";

export const DocumentsField = () => {
  return (
    <ReferenceArrayField reference="documents" source="documentIds">
      <Datagrid expand={<PreviewPanel />} bulkActionButtons={false}>
        <TextField source="context" />
        <DateField source="createdAt" label="Date" />
        <DocumentLinkField bucketName="interventions" />
      </Datagrid>
    </ReferenceArrayField>
  );
};

const PreviewPanel = () => {
  const record = useRecordContext<Document>();

  if (!record) {
    return null;
  }

  if (["jpg", "jpeg", "png"].includes(record.extension)) {
    return (
      <img
        alt={record.context}
        src={getDocumentURL("interventions", record)}
        style={{ maxHeight: 400, maxWidth: 400 }}
      />
    );
  }

  return <div>No preview yet for extensions {record.extension}</div>;
};
