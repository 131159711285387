import { sortBy } from "lodash";
import {
  AutocompleteArrayInput,
  useGetManyReference,
  useRecordContext,
} from "react-admin";
import type { Project } from "../../project/type";
import type { Residence } from "../../residence/types";
import type { ResidenceContact } from "../types";

export function ResidencesAutocompleteArrayInput() {
  const residenceContact = useRecordContext<ResidenceContact>();

  const { data: projects } = useGetManyReference<Project>("projects", {
    target: "organizationId",
    id: residenceContact?.organizationId ?? "",
  });

  return (
    <AutocompleteArrayInput
      disableCloseOnSelect={true}
      blurOnSelect={false}
      inputText={getOptionText(projects ?? [])}
      optionText={getOptionText(projects ?? [])}
    />
  );
}

const getLastDeployment = (residence: Residence) => {
  const projectsWithDeploymentDate = residence.projects.filter(
    (p) => p.deploymentStartFormattedDate,
  );
  return (
    projectsWithDeploymentDate.length > 0
      ? sortBy(projectsWithDeploymentDate, "deploymentStartFormattedDate")
      : residence.projects
  ).at(-1);
};

const getOptionText = (projects: Project[]) => (residence: Residence) => {
  if (!residence || !residence.esi) {
    return "⚠️ No ESI number";
  }

  const { address, zipCode, city } = residence;
  const fullAddress =
    [address, [zipCode, city].join(" ")].join(", ") || "No Address";

  const { projectId } = getLastDeployment(residence) ?? {};
  const lastProject = projects.find((project) => project.id === projectId);

  return `🏢 ${[residence.esi, fullAddress, lastProject?.name].join(" — ")}`;
};
