import { useAxiosQuery } from "../../providers/data-hooks/useAxiosQuery";

export const useCommunicationDetails = (communicationId?: string) =>
  useAxiosQuery<{
    recipient?: string;
    preview?: {
      title?: string;
      content?: string;
      type?: "html" | "pdf" | "text";
    };
    editionLink?: string;
  }>(
    { url: `/communications/${communicationId}/details` },
    { enabled: !!communicationId },
  );
