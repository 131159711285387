export enum Mode {
  SET_POINT = "SetPoint",
  OBSERVATION = "Observation",
  DEPRECATED_PRE_OBSERVATION = "PreObservation",
  SCHEDULE = "Schedule",
  SMART = "Smart",
  SMART_ENERGY = "Smart Energy",
  DEPRECATED_SMART_FLEX = "Smart Flex EDF",
  DEPRECATED_SMART_FLEX_v2 = "Smart Flex EDF V2",
}

export const STATIC_MODES = [
  Mode.SET_POINT,
  Mode.OBSERVATION,
  Mode.DEPRECATED_PRE_OBSERVATION,
];
export const OBSERVATION_MODES = [
  Mode.OBSERVATION,
  Mode.DEPRECATED_PRE_OBSERVATION,
];
export const SMART_MODES = [Mode.SMART, Mode.SMART_ENERGY];
