import { Grid } from "@mui/material";
import {
  ArrayInput,
  BooleanInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  required,
} from "react-admin";
import type { EditFormWithDialogProps } from "../../../display/EditFormDialog";
import { EditFormDialog } from "../../../display/EditFormDialog";
import { validationStatusLabels } from "../fields/ContactValidationStatusField";
import type { EndUser } from "../type";

type EndUserContactsForm = {
  emails: string[];
  phoneNumbers: string[];
};

export function EditContactsDialog({ dialogProps }: EditFormWithDialogProps) {
  return (
    <EditFormDialog<EndUser, EndUserContactsForm>
      dialogProps={dialogProps}
      getMutationAxiosConfig={(record) => ({
        method: "PUT",
        url: `/end-users/${record?.id}/contacts`,
      })}
      getFormRecordFromRecord={(record) => ({
        emails: record.contacts.emails.validated,
        phoneNumbers: record.contacts.phoneNumbers.validated,
      })}
    >
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <ArrayInput source="emails" label="Emails" fullWidth>
            <SimpleFormIterator fullWidth>
              <TextInput type="email" source="" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid item sm={12}>
          <ArrayInput
            source="phoneNumbers"
            type="tel"
            label="Phone Numbers"
            fullWidth
          >
            <SimpleFormIterator fullWidth>
              <TextInput source="" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid item sm={8}>
          <SelectInput
            source="validationStatus"
            fullWidth
            label="Validation Status"
            helperText="Status of the contact validation for any *new* emails or phone numbers above"
            defaultValue="backOffice:otherUnreliable"
            validate={required()}
            choices={[
              {
                id: "backOffice:givenByUser",
                name: validationStatusLabels["backOffice:givenByUser"],
              },
              {
                id: "backOffice:otherReliable",
                name: validationStatusLabels["backOffice:otherReliable"],
              },
              {
                id: "backOffice:otherUnreliable",
                name: validationStatusLabels["backOffice:otherUnreliable"],
              },
            ]}
          />
        </Grid>
        <Grid item sm={4}>
          <BooleanInput
            source="validationStatusApplyToAll"
            fullWidth
            label="Apply to all"
            helperText="If ticked, apply the validation status to all emails and phone numbers above instead of only the *new* ones."
          />
        </Grid>
      </Grid>
    </EditFormDialog>
  );
}
