import { countBy, orderBy, uniqBy } from "lodash";
import { useGetList, useListContext, useRecordContext } from "react-admin";
import { useToggleFilter } from "../../../filters/useToggleFilter";
import type { Deal } from "../../deal/type";
import type { Project } from "../../project/type";
import { InterventionType } from "../types";
import type { Intervention } from "../types";

export const useInstallationPieFilter = (
  source: keyof Intervention,
  entity: "deal" | "project",
) => {
  const record = useRecordContext<Deal | Project>();

  const { data: total } = useGetList<Intervention>("interventions", {
    filter: {
      type: InterventionType.INSTALLATION,
      [entity === "deal" ? "dealId" : "projectId"]: record?.id,
    },
    pagination: { perPage: 100_000, page: 1 },
  });

  const { data } = useListContext<Intervention>();

  const [filteredValue, toggleFilter] = useToggleFilter(source);

  const usedData = uniqBy(
    orderBy(filteredValue ? total : data, "datetime", "desc"),
    (i) => i.housingId.toString(),
  );

  const counts =
    usedData && countBy(usedData, (intervention) => intervention[source]);

  return { counts, toggleFilter, filteredValue };
};
