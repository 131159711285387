import ResidenceIcon from "@mui/icons-material/Apartment";
import { ResidenceCreate, ResidenceEdit } from "./Edit";
import { ResidenceList } from "./List";
import { ResidenceShow } from "./show";
import type { Residence } from "./types";

export const residenceResource = {
  icon: ResidenceIcon,
  list: ResidenceList,
  edit: ResidenceEdit,
  create: ResidenceCreate,
  show: ResidenceShow,
  recordRepresentation: (record: Residence) => {
    if (!record.esi) {
      return "⚠️ No ESI number";
    }

    return `🏢 ${record.esi}`;
  },
};

export { default as ResidenceIcon } from "@mui/icons-material/Apartment";
