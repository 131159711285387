import Icon from "@mui/icons-material/DoDisturb";
import type { SvgIconTypeMap } from "@mui/material";
import type { DefaultComponentProps } from "@mui/material/OverridableComponent";
import { useRecordContext } from "react-admin";
import type { DatagridOrFilterRequiredProps } from "../../../layout/types";
import type { BalancingBid } from "../types";

type BalancingBidCancelledFieldProps = DefaultComponentProps<SvgIconTypeMap> &
  DatagridOrFilterRequiredProps;

export const BalancingBidCancelledField = (
  props: BalancingBidCancelledFieldProps,
) => {
  const record = useRecordContext<BalancingBid>();

  if (!record?.cancelledAt) {
    return null;
  }

  return <Icon color="error" {...props} />;
};
