import { DateTimeInput } from "react-admin";
import { useWatch } from "react-hook-form";
import { interventionClosedStatuses } from "../types";

export const DatetimeInput = () => {
  const status = useWatch({ name: "status" });
  const canEditDatetime = interventionClosedStatuses.includes(status);

  return (
    <DateTimeInput
      label="Intervention Date"
      source="datetime"
      required={false}
      fullWidth
      disabled={!canEditDatetime}
      helperText={
        canEditDatetime
          ? undefined
          : "Ne peut pas être mise à jour pour une intervention non terminée (succès, échec ou pas de RDV)."
      }
    />
  );
};
