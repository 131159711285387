import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useURLFilter } from "./useURLFilter";

type RecentDateFilterProps = {
  source: string;
  label: string;
};

export const RecentDateFilter = ({ source, label }: RecentDateFilterProps) => {
  const [date, setValue] = useURLFilter<any>(source);

  const value = date?.$gte ? decodeValueInDate(date.$gte) : 5;

  useEffect(() => {
    setValue({ $gte: encodeValueInDate(value) });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- to fix
  }, [value]);

  return (
    <TextField
      size="small"
      value={value}
      label={label}
      type="number"
      onChange={(event) =>
        setValue({ $gte: encodeValueInDate(Number(event.target.value)) })
      }
    />
  );
};

const encodeValueInDate = (value: number) =>
  dayjs()
    .subtract(value, "days")
    .startOf("day")
    .add(value, "milliseconds")
    .toDate();

const decodeValueInDate = (date: Date) => dayjs(date).get("milliseconds");
