import { ArrayInput, SelectInput, SimpleFormIterator } from "react-admin";
import { useWatch } from "react-hook-form";
import { NoWheelNumberInput } from "../../../fields/NumberInput";
import { integer } from "../../../validations/integer";
import { ScheduleChart } from "../../devices/show/tabs/pilot/Schedule";

export const ScheduleParameters = ({
  source,
  majorHWVersion,
}: {
  source: string;
  majorHWVersion?: 1 | 2 | 3;
}) => {
  const weeklyInstructions = useWatch({ name: `${source}.weeklyInstructions` });

  return (
    <>
      <ArrayInput
        source={`${source}.weeklyInstructions`}
        label={false}
        fullWidth
      >
        <SimpleFormIterator
          inline
          sx={{
            "& .RaSimpleFormIterator-line": {
              borderBottom: "none",
              padding: "4px",
            },
            "& .RaSimpleFormIterator-inline": {
              alignItems: "center",
            },
          }}
        >
          <SelectInput
            source="time.weekDay"
            choices={[
              { id: "*", name: "Everyday" },
              { id: 1, name: "Monday" },
              { id: 2, name: "Tuesday" },
              { id: 3, name: "Wednesday" },
              { id: 4, name: "Thursday" },
              { id: 5, name: "Friday" },
              { id: 6, name: "Satursday" },
              { id: 0, name: "Sunday" },
            ]}
            helperText={false}
            sx={{ marginTop: "4px" }}
            required
          />
          <NoWheelNumberInput
            source="time.hour"
            label="Hour"
            min={0}
            max={23}
            helperText={false}
            sx={{ width: 80 }}
            required
            validate={integer}
          />
          <NoWheelNumberInput
            source="time.minute"
            label="Minute"
            min={0}
            max={59}
            helperText={false}
            sx={{ width: 80 }}
            required
            validate={integer}
          />
          <NoWheelNumberInput
            source="setPoint"
            label={`SetPoint${majorHWVersion === 3 ? " 1" : ""} [1°C]`}
            helperText={false}
            sx={{ width: 120 }}
            required
            step={1}
            validate={integer}
          />
          <NoWheelNumberInput
            source="hysteresis"
            label={`Hysteresis${majorHWVersion === 3 ? " 1" : ""} [0.1°C]`}
            helperText={false}
            sx={{ width: 140 }}
            required
            step={1}
            max={256}
            validate={integer}
          />
          {majorHWVersion === 3 ? (
            <NoWheelNumberInput
              source="setPoint2"
              label="SetPoint 2 [1°C]"
              helperText={false}
              sx={{ width: 120 }}
              required
              step={1}
              validate={integer}
            />
          ) : null}
          {majorHWVersion === 3 ? (
            <NoWheelNumberInput
              source="hysteresis2"
              label="Hysteresis 2 [0.1°C]"
              helperText={false}
              sx={{ width: 140 }}
              required
              step={1}
              max={256}
              validate={integer}
            />
          ) : null}
        </SimpleFormIterator>
      </ArrayInput>
      {weeklyInstructions?.length > 0 && (
        <ScheduleChart
          weeklyInstructions={weeklyInstructions.filter(isCompleteInstruction)}
        />
      )}
    </>
  );
};

const isCompleteInstruction = (i: any) =>
  [i.time?.weekDay, i.time?.hour, i.time?.minute, i.setPoint].every(
    (v) => typeof v === "number" || v === "*",
  );
