export const getErrorMessage = (maybeError: unknown) => {
  if (isErrorWithMessage(maybeError)) {
    return maybeError.message;
  }
  return String(maybeError);
};

type ErrorWithMessage = {
  message: string;
};

export function toError(maybeError: unknown): Error {
  if (maybeError instanceof Error) {
    return maybeError;
  }
  const newError = new Error(getErrorMessage(maybeError));
  if (isErrorWithStack(maybeError)) newError.stack = maybeError.stack;
  return newError;
}

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
}

type ErrorWithStack = {
  stack: string;
};

function isErrorWithStack(error: unknown): error is ErrorWithStack {
  return (
    typeof error === "object" &&
    error !== null &&
    "stack" in error &&
    typeof (error as Record<string, unknown>).stack === "string"
  );
}
