import type { RaRecord, UseGetListOptions } from "react-admin";
import { useGetList } from "react-admin";

export const useGetCount = <RecordType extends RaRecord>(
  resource: string,
  filter: any,
  queryOptions?: UseGetListOptions<RecordType>,
) => {
  const { total } = useGetList(
    resource,
    {
      filter,
      pagination: { page: 1, perPage: 2 }, // perPage=1 generate a miscount
    },
    queryOptions,
  );

  return total;
};
