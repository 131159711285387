import { Grid } from "@mui/material";
import { Children } from "react";
import type { ReactNode } from "react";

export const FieldGrid = ({
  children,
  columns,
}: {
  children: ReactNode;
  columns?: number;
}) => {
  return (
    <Grid container spacing={1} columnSpacing={8} padding={1} columns={columns}>
      {Children.map(children, (child) => (
        <Grid item xs={columns ? 1 : "auto"}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};
