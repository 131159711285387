import OrganizationIcon from "@mui/icons-material/RocketLaunch";
import { OrganizationEdit } from "./Edit";
import { OrganizationList } from "./List";
import { OrganizationShow } from "./show";
import type { Organization } from "./types";

export const organizationResource = {
  icon: OrganizationIcon,
  list: OrganizationList,
  edit: OrganizationEdit,
  show: OrganizationShow,
  recordRepresentation: (record: Organization) => record.name,
};

export { default as ClientIcon } from "@mui/icons-material/RocketLaunch";
