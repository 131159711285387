import { FormControlLabel, Switch } from "@mui/material";
import { useRecordContext } from "react-admin";
import { useAxiosMutation } from "../../../../../providers/data-hooks/useAxiosQuery";
import type { Device } from "../../../types";

export function DisableDemandResponse() {
  const device = useRecordContext<Device>();
  const mutation = useAxiosMutation({
    url: `/devices/${device?.id}/disable-demand-response`,
    method: "PUT",
  });

  if (!device) return null;

  return (
    <FormControlLabel
      control={
        <Switch
          checked={device.disabledDemandResponse}
          onChange={(_, value) => {
            mutation.mutate({
              disabledDemandResponse: value,
            });
          }}
        />
      }
      label="Disable demand response"
    />
  );
}
