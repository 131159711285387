import { Divider, Grid, Typography } from "@mui/material";
import { IfCanAccess } from "@react-admin/ra-rbac";
import {
  Datagrid,
  DateField,
  ReferenceField,
  ReferenceManyField,
  ReferenceOneField,
  ShowButton,
  TextField,
  WithRecord,
} from "react-admin";
import { InlineLabel } from "../../../../../layout/InlineLabel";
import { MarkdownPanel } from "../../../../../layout/MarkdownPanel";
import { ContactsField } from "../../../../end-users/fields/ContactsField";
import { NameField } from "../../../../end-users/fields/NameField";
import { ZendeskUserUrlField } from "../../../../end-users/fields/ZendeskUserUrlField";
import { EquipedField } from "../../../../housing/fields/EquipedDate";
import { InterventionDevice } from "../../../../intervention/fields/Device";
import { InterventionStatusField } from "../../../../intervention/fields/status";
import { InterventionTypeField } from "../../../../intervention/fields/type";
import { Alerts } from "../../../fields/Alerts";
import { SettingsField } from "../../../fields/Configuration";
import { HardwareField } from "../../../fields/Hardware";
import { HeaterField } from "../../../fields/Heater";
import { TelecomField } from "../../../fields/Telecom";

export const SummaryTab = () => {
  return (
    <>
      <Grid container spacing={2}>
        <IfCanAccess resource="alerts" action="read">
          <Grid item xs={12}>
            <ReferenceManyField
              reference="alerts"
              target="deviceId"
              filter={{ timeEnd: { $exists: false }, includeMuted: true }}
              perPage={1000}
            >
              <Alerts />
            </ReferenceManyField>
          </Grid>
        </IfCanAccess>

        <Grid item xs={12} md={6}>
          <SettingsField />
        </Grid>

        <Grid item xs={12} md={6}>
          <HardwareField />
        </Grid>

        <Grid item xs={12} md={6}>
          <TelecomField />
        </Grid>

        <IfCanAccess resource="heaters" action="read">
          <Grid item xs={12} md={6}>
            <ReferenceField reference="heaters" source="heater" link={false}>
              <HeaterField />
            </ReferenceField>
          </Grid>
        </IfCanAccess>

        <IfCanAccess resource="housings" action="read">
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ReferenceField
              reference="housings"
              source="housingId"
              link={false}
            >
              <Grid container>
                <Grid item xs={12} md={3}>
                  <Typography variant="h4">
                    Housing <ShowButton />
                  </Typography>
                  <InlineLabel label="Unit ID">
                    <TextField source="unitId" variant="body1" />
                  </InlineLabel>
                  <InlineLabel label="PDL">
                    <TextField source="pdl" variant="body1" />
                  </InlineLabel>
                  <InlineLabel label="Address">
                    <TextField source="address" variant="body1" />
                  </InlineLabel>
                  <InlineLabel label="ZipCode">
                    <TextField source="zipCode" variant="body1" emptyText="-" />
                  </InlineLabel>
                  <InlineLabel label="City">
                    <TextField source="city" variant="body1" emptyText="-" />
                  </InlineLabel>
                  <InlineLabel label="Equiped date">
                    <EquipedField />
                  </InlineLabel>
                  <InlineLabel label="Entité d'effacement">
                    <WithRecord
                      render={(record) =>
                        record.pdl ? (
                          <ReferenceOneField
                            reference="demand-response-entities"
                            source="pdl"
                            target="pdl"
                          >
                            <TextField
                              source="name"
                              variant="body1"
                              emptyText="-"
                            />
                          </ReferenceOneField>
                        ) : (
                          <></>
                        )
                      }
                    />
                  </InlineLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <ReferenceManyField reference="end-users" target="housingIds">
                    <Datagrid
                      bulkActionButtons={false}
                      expand={<MarkdownPanel source="notes" />}
                      isRowExpandable={(record) => record.notes}
                    >
                      <NameField label="name" />
                      <ContactsField type="emails" />
                      <ContactsField type="phoneNumbers" />
                      <ZendeskUserUrlField
                        label="Open user in Zendesk"
                        title="Open in Zendesk"
                      />
                    </Datagrid>
                  </ReferenceManyField>
                </Grid>
              </Grid>
            </ReferenceField>
            <Grid item xs={12} marginTop={2}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">
                Interventions <ShowButton />
              </Typography>
              <ReferenceManyField
                reference="interventions"
                target="deviceId"
                sort={{ field: "datetime", order: "DESC" }}
                perPage={1000}
              >
                <Datagrid bulkActionButtons={false}>
                  <InterventionTypeField label="Type" />
                  <InterventionStatusField label="Status" />
                  <InterventionDevice label="Device" />
                  <DateField label="Date" source="datetime" showTime />
                  <ReferenceField
                    reference="housings"
                    source="housingId"
                    link="show"
                  >
                    <TextField source="unitId" />
                  </ReferenceField>
                </Datagrid>
              </ReferenceManyField>
            </Grid>
          </Grid>
        </IfCanAccess>
      </Grid>
    </>
  );
};
