import EndUserIcon from "@mui/icons-material/AccountBox";
import { EndUserEdit } from "./Edit";
import { EndUserList } from "./List";
import { serializeEndUserName } from "./fields/NameField";
import { EndUserShow } from "./show/Show";
import type { EndUser } from "./type";

export const endUserResource = {
  icon: EndUserIcon,
  list: EndUserList,
  edit: EndUserEdit,
  show: EndUserShow,
  options: { label: "End Users" },
  recordRepresentation: (record: EndUser) => serializeEndUserName(record),
};

export { default as EndUserIcon } from "@mui/icons-material/AccountBox";
