import { FunctionField } from "react-admin";
import type { FunctionFieldProps } from "react-admin";
import type { Except } from "type-fest";
import type { Residence } from "../types";

export const ResidenceAddressField = (
  props: Except<FunctionFieldProps<Residence>, "render">,
) => {
  return (
    <FunctionField<Residence>
      label="Address"
      render={(record) =>
        record && (
          <>{record.address + " " + record.zipCode + " " + record.city}</>
        )
      }
      {...props}
    />
  );
};
