import { OpenInNew } from "@mui/icons-material";
import { Link, Tooltip } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import dayjs from "dayjs";
import { get } from "lodash";
import { useRecordContext } from "react-admin";
import type { ConsentType, EndUser } from "../type";
import { consentTypeLabel } from "../type";

type ConsentFieldProps = {
  type: ConsentType;
  source?: string;
  label?: string;
  display?: "label" | "date";
};

export function ConsentField({
  type,
  source = "consents." + type,
  display = "label",
}: ConsentFieldProps) {
  const record = useRecordContext<EndUser>();

  if (!record) {
    return null;
  }
  const value = get(record, source);

  const color =
    value?.consent === true ? green : value?.consent === false ? red : grey;

  const collectDate = value
    ? `Collecté le ${dayjs(value.date).format("DD/MM/YYYY")}`
    : `Non collecté`;
  return (
    <Tooltip title={collectDate}>
      <Link
        href={value?.proofUrl}
        target="_blank"
        sx={{
          backgroundColor: color["50"],
          color: color["400"],
          borderRadius: 1,
          textAlign: "center",
          fontWeight: "bold",
          paddingX: 1,
          paddingY: 0.2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "min-content",
          whiteSpace: "nowrap",
          marginY: 0.2,
          textDecoration: "none",
          gap: 0.4,
        }}
      >
        {display === "date" ? collectDate : consentTypeLabel[type]}
        {value?.proofUrl && <OpenInNew fontSize="small" />}
      </Link>
    </Tooltip>
  );
}
