import type { IncidentActor } from "@elax/business-types";
import { IncidentStatus } from "@elax/business-types";
import { Tab } from "@mui/material";
import type { TabProps } from "@mui/material";
import type { Except } from "type-fest";
import { JsonParam, useQueryParam, withDefault } from "use-query-params";
import { useGetCount } from "../../../providers/data-hooks/useGetCount";
import { incidentActorLabels } from "../types";

type TabWithCountProps = Except<TabProps, "label"> & {
  assignedTo: IncidentActor;
};

export function IncidentAssignedToTab({
  assignedTo,
  ...props
}: TabWithCountProps) {
  const [filter] = useQueryParam("filter", withDefault(JsonParam, {}));
  const count = useGetCount("incidents", {
    status: IncidentStatus.OPENED,
    ...filter,
    assignedTo,
  });

  return (
    <Tab
      value={assignedTo}
      label={`${incidentActorLabels[assignedTo].label} (${count ?? "..."})`}
      {...props}
    />
  );
}
