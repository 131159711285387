import { getErrorMessage } from "@elax/utils";
import Icon from "@mui/icons-material/Add";
import {
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useState } from "react";
import { useNotify, useRecordContext, useRefresh } from "react-admin";
import {
  ActionDialog,
  ActionDialogButton,
  DialogActionMenuItem,
} from "../../../../layout/actions-menu";
import { dataProvider } from "../../../../providers/data";
import { SelectDevice } from "../../../devices/fields/SelectDevice";
import { DeviceStatus } from "../../../devices/types";
import type { Device } from "../../../devices/types";
import type { Housing } from "../../types";

export const InstallAction = () => {
  const housing = useRecordContext<Housing>();
  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
  const [comment, setComment] = useState("");
  const notify = useNotify();
  const refresh = useRefresh();

  const { mutate: install } = useMutation({
    mutationKey: ["install", selectedDevice?.id],
    mutationFn: async () =>
      selectedDevice &&
      housing &&
      dataProvider.installDevice(
        selectedDevice.id,
        housing.id,
        dayjs(date).format(),
        comment,
      ),
    onSuccess: () => {
      notify("Device installed", { type: "success" });
      refresh();
    },
    onError: (error: unknown) => {
      notify(`Failed to install device: ${getErrorMessage(error)}`, {
        type: "error",
      });
    },
  });

  return (
    <DialogActionMenuItem
      label="Install device"
      icon={<Icon />}
      dialog={
        <ActionDialog>
          <DialogContent>
            <Typography variant="h5">Install a device</Typography>
            <Typography variant="caption">
              Heater, PDL, end user data can be edited separately
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  value={date}
                  onChange={(event) => {
                    if (event.target.value) setDate(event.target.value);
                  }}
                  type="date"
                  label="Intervention Date"
                  fullWidth
                  sx={{ marginTop: 2 }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <SelectDevice
                  label="Device"
                  value={selectedDevice}
                  onChange={(device) => {
                    setSelectedDevice(device);
                  }}
                  filter={{ status: DeviceStatus.STOCK }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Comment"
                  value={comment}
                  onChange={(event) => {
                    setComment(event.target.value);
                  }}
                  fullWidth
                  multiline
                  minRows={3}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ActionDialogButton>Cancel</ActionDialogButton>
            <ActionDialogButton
              variant="contained"
              disabled={!selectedDevice || !housing || !date}
              onClick={(event, close) => {
                install();
                close();
              }}
            >
              Install
            </ActionDialogButton>
          </DialogActions>
        </ActionDialog>
      }
    />
  );
};
