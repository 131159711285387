import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import type { Instruction } from "../data/charts/schedule-series";

dayjs.extend(isoWeek);

export const ScheduleList = ({
  instructions,
}: {
  instructions: Instruction[];
}) => {
  return (
    <ul>
      {instructions.map((instruction, index) => (
        <li key={index}>
          {instruction.time.weekDay === "*"
            ? "Everyday"
            : dayjs().isoWeekday(instruction.time.weekDay).format("dddd")}{" "}
          {instruction.time.hour.toString().padStart(2, "0")}:
          {instruction.time.minute.toString().padStart(2, "0")}:{" "}
          {instruction.setPoint}°C ±{(instruction.hysteresis / 10).toFixed(1)}°C
        </li>
      ))}
    </ul>
  );
};
