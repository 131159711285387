import { useEffect } from "react";
import { useGetList, useNotify } from "react-admin";
import { useNavigate, useParams } from "react-router-dom";

export const RedirectIMEI = ({ children }: { children: any }) => {
  const params = useParams();
  const navigate = useNavigate();
  const notify = useNotify();
  const searchIMEI = params.id ? isIMEI(params.id) : false;
  const { data } = useGetList(
    "devices",
    { filter: { IMEI: params.id }, pagination: { page: 1, perPage: 1 } },
    { enabled: searchIMEI },
  );

  useEffect(() => {
    if (searchIMEI && data?.length === 0) {
      navigate("/devices");
      notify("Element does not exists", { type: "error" });
    }

    if (searchIMEI && data) {
      navigate(`/devices/${data[0].id}/show`, { replace: true });
    }
  }, [navigate, notify, data, searchIMEI]);

  if (searchIMEI) {
    return null;
  }

  return children;
};

const isIMEI = (string: string) => {
  return !Number.isNaN(+string) && string.length === 15;
};
