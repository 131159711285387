import {
  Box,
  IconButton,
  Menu,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { sortBy } from "lodash";
import { cloneElement, useEffect, useRef, useState } from "react";
import { useDateRangeFilter } from "../../../../../../filters/useDateRangeFilter";
import type { ConfigAckFrame } from "../../../../../frames/config-ack/type";
import { useFrameData } from "../useFrameData";
import { Datetime, DatetimeFilter } from "./Datetime";
import { FrameDetail, FrameDetailToggler } from "./FrameDetail";
import { FrameFilters, useFrameTypeData } from "./FrameFilter";
import { configFrameSpecs } from "./config";
import { configAckFrameSpecs } from "./config-ack";
import { dataFrameSpecs } from "./data";
import { debugFrameSpecs } from "./debug";
import { networkFrameSpecs } from "./network";
import { productionFrameSpecs } from "./production";
import type { FrameSpecs, FrameType } from "./shared";
import { statusFrameSpecs } from "./status";

export const frameSpecs: Record<FrameType, FrameSpecs> = {
  data: dataFrameSpecs,
  "config-ack": configAckFrameSpecs,
  config: configFrameSpecs,
  production: productionFrameSpecs,
  status: statusFrameSpecs,
  debug: debugFrameSpecs,
  network: networkFrameSpecs,
};

export const AllFrames = ({ cursor }: { cursor: number | undefined }) => {
  const { dateRange } = useDateRangeFilter();
  const {
    configAckFrames,
    dataFrames,
    statusFrames,
    productionFrames,
    debugFrames,
    configFrames,
    networkFrames,
  } = useFrameData(dateRange);
  const [{ anchor, type: menuType }, setAnchorEl] = useState<{
    anchor: null | HTMLElement;
    type: FrameType;
  }>({ anchor: null, type: "data" });

  const filteredDataFrames = useFrameTypeData("data", dataFrames);
  const filteredConfigAckFrames = useFrameTypeData(
    "config-ack",
    configAckFrames,
  );
  const filteredStatusFrames = useFrameTypeData("status", statusFrames);
  const filteredProductionFrames = useFrameTypeData(
    "production",
    productionFrames,
  );
  const filteredDebugFrames = useFrameTypeData("debug", debugFrames);
  const filteredConfigFrames = useFrameTypeData("config", configFrames);
  const filteredNetworkFrames = useFrameTypeData("network", networkFrames);

  const allFrames = sortBy(
    [
      ...filteredDataFrames,
      ...filteredConfigAckFrames,
      ...filteredStatusFrames,
      ...filteredProductionFrames,
      ...filteredDebugFrames,
      ...filteredConfigFrames,
      ...filteredNetworkFrames,
    ],
    ({ frame }) => -frame.timestamp,
  ).map(({ frame, type }, index, frames) => {
    if (type !== "config") {
      return { frame, type } as const;
    }
    let checkIndex = index - 1;

    while (
      checkIndex >= 0 &&
      frame.timestamp - frames[checkIndex].frame.timestamp <= 60 * 1000
    ) {
      if (
        frames[checkIndex].type === "config-ack" &&
        (frames[checkIndex].frame as ConfigAckFrame).set_point ===
          frame.set_point &&
        (frames[checkIndex].frame as ConfigAckFrame).hysteresis ===
          frame.hysteresis
      ) {
        return {
          type,
          frame: {
            ...frame,
            acknowledgedAt: frames[checkIndex].frame.ts,
          } as const,
        };
      }
      checkIndex = checkIndex - 1;
    }

    return checkIndex < 0
      ? {
          type,
          frame: {
            ...frame,
            acknowledgedAt: null,
          } as const,
        }
      : {
          type,
          frame: {
            ...frame,
            acknowledgedAt: false,
          } as const,
        };
  });

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    const element = cursor && document.getElementById(`${cursor}`);
    if (element && container) {
      container.scroll(0, Math.max(element.offsetTop - 80, 0));
    }
  }, [cursor]);

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        <FrameFilters />
      </Box>
      {(Object.keys(frameSpecs) as FrameType[]).map((type) => (
        <Menu
          anchorEl={anchor}
          open={Boolean(anchor) && type === menuType}
          onClose={() => setAnchorEl({ anchor: null, type: "data" })}
          key={type}
        >
          <DatetimeFilter />
          {frameSpecs[type].data.map((d) => (
            <FrameDetailToggler key={d.key} type={type} detailKey={d.key} />
          ))}
        </Menu>
      ))}
      <TableContainer
        component={Paper}
        sx={{ overflowY: "auto", maxHeight: "800px" }}
        ref={containerRef}
      >
        <Table size="small">
          <TableBody>
            {allFrames.map(({ frame, type }) => (
              <TableRow
                key={frame.id}
                id={frame.timestamp.toString()}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                  backgroundColor:
                    frame.timestamp && frame.timestamp === cursor
                      ? "#ff572299"
                      : undefined,
                }}
              >
                <TableCell component="th" scope="row">
                  <Box display="flex" alignItems="center">
                    <IconButton
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        setAnchorEl({
                          anchor: event.currentTarget,
                          type,
                        });
                      }}
                      key={type}
                      sx={{ marginRight: 1 }}
                    >
                      {cloneElement(frameSpecs[type].icon, {
                        fontSize: "small",
                      })}
                    </IconButton>
                    <Datetime frame={frame} />
                  </Box>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Box display="flex" alignItems="center">
                    {frameSpecs[type].data.map((element) => {
                      return (
                        <FrameDetail
                          key={element.key}
                          detailKey={element.key}
                          type={type}
                        >
                          {element.label(frame)}
                        </FrameDetail>
                      );
                    })}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
