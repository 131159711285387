import { Paper } from "@mui/material";
import { useRef, useState } from "react";
import { useMeasure } from "react-use";
import { useAxiosQuery } from "../../../providers/data-hooks/useAxiosQuery";

type MetabaseDashboardProps = {
  dashboardId: number;
  filter?: Record<string, string | number>;
  initialHeight?: number;
};

export const MetabaseDashboard = ({
  dashboardId,
  filter,
  initialHeight = 1200,
}: MetabaseDashboardProps) => {
  const { data } = useAxiosQuery<string>({
    url: `/metabase/dashboard/${dashboardId}`,
    params: filter,
  });

  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const [height, setHeight] = useState(initialHeight);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  if (!data) {
    return null;
  }

  return (
    <Paper ref={ref} sx={{ display: "flex", flexGrow: 1 }}>
      <iframe
        src={data}
        ref={iframeRef}
        frameBorder={0}
        width={width}
        height={height}
        scrolling="no"
        onLoad={() => {
          try {
            setHeight(
              iframeRef.current?.contentWindow?.document?.body?.scrollHeight ??
                initialHeight,
            );
          } catch {
            // on cross origin document is not accessible
          }
        }}
      />
    </Paper>
  );
};
