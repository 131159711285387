import ProjectIcon from "@mui/icons-material/FactCheck";
import { ProjectCreate, ProjectEdit } from "./Edit";
import { ProjectList } from "./List";
import { ProjectShow } from "./show";
import type { Project } from "./type";

export const projectResource = {
  icon: ProjectIcon,
  list: ProjectList,
  show: ProjectShow,
  edit: ProjectEdit,
  create: ProjectCreate,
  recordRepresentation: (record: Project) => `📦 ${record.name}`,
};

export { default as ProjectIcon } from "@mui/icons-material/FactCheck";
