import { get, set } from "lodash";
import { useCallback, useEffect } from "react";
import { JsonParam, useQueryParam, withDefault } from "use-query-params";
import type { z } from "zod";

export const useURLFilter = <Value>(
  source: string,
  valueSchema?: z.ZodSchema<Value>,
  nestQuery = true,
): [Value | undefined, (newValue: Value | undefined) => void] => {
  const [filterValue, setURLValue] = useQueryParam(
    "filter",
    withDefault(JsonParam, {}),
  );

  useEffect(() => {
    const path = source.split(".").slice(0, -1).join(".");

    const value = get(filterValue, path);

    if (value && typeof value === "object" && Object.keys(value).length === 0) {
      setURLValue((previous: any) => ({ ...set(previous, path, undefined) }));
    }
  }, [filterValue, setURLValue, source]);

  const setValue = useCallback(
    (newValue: Value | undefined) => {
      setURLValue((previous: any) => ({
        ...(nestQuery
          ? set(previous, source, newValue)
          : { ...previous, [source]: newValue }),
      }));
    },
    [source, setURLValue, nestQuery],
  );

  const value = get(filterValue, source);

  if (!valueSchema) {
    return [value, setValue];
  }

  const { data } = valueSchema.safeParse(value);

  return [data, setValue];
};
