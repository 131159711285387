import { useQuery } from "@tanstack/react-query";
import type { HousingStatus } from "../../resources/housing/types";
import { axiosClient } from "../data";

export const useDeploymentSummary = (
  entity: "project" | "deal",
  recordId: string,
) => {
  return useQuery<{
    endUsersContactedCount: number;
    housingCount: number;
    housingCountByStatus: Record<HousingStatus, number>;
    penetrationRate: number;
    billingRate: number;
  }>({
    queryKey: [entity, recordId, "deployment-summary"],
    queryFn: () =>
      axiosClient
        .get(`/${entity}s/${recordId}/deployment-summary`)
        .then((res) => res.data),
  });
};
