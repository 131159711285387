import { SelectInput, required } from "react-admin";
import type { SelectInputProps } from "react-admin";
import { useWatch } from "react-hook-form";
import type { Intervention } from "../types";
import {
  InterventionDeviceLocationAfterRemoval,
  InterventionType,
  interventionDeviceLocationAfterRemovalLabels,
} from "../types";

const deviceLocationAfterRemovalChoices = Object.values(
  InterventionDeviceLocationAfterRemoval,
).map((value) => ({
  id: value,
  name: interventionDeviceLocationAfterRemovalLabels[value],
}));

export const DeviceLocationAfterRemovalInput = (props: SelectInputProps) => {
  const interventionType = useWatch<Intervention, "type">({ name: "type" });

  if (interventionType !== InterventionType.REMOVAL) {
    return null;
  }

  return (
    <SelectInput
      source="deviceLocationAfterRemoval"
      label="Device location after removal"
      choices={deviceLocationAfterRemovalChoices}
      validate={required()}
      fullWidth
      {...props}
    />
  );
};
