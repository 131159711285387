import { Typography } from "@mui/material";
import { QueryClient } from "@tanstack/react-query";
import { mapValues } from "lodash";
import { SnackbarProvider } from "notistack";
import { useEffect } from "react";
import {
  Admin,
  CustomRoutes,
  Link,
  LoginForm,
  Resource,
  useRedirect,
} from "react-admin";
import { HashRouter, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { Layout } from "./layout";
import { NoLayoutCard } from "./layout/NoLayoutCard";
import { Dashboard } from "./layout/dashboard";
import { ForgotPassword } from "./layout/forgot-password";
import { SetPassword } from "./layout/set-password";
import { withPageTitle } from "./layout/withPageTitle";
import { authProvider } from "./providers/auth";
import { dataProvider } from "./providers/data";
import { userStore } from "./providers/store";
import { resources } from "./resources";
import { elaxTheme } from "./theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2 * 60 * 1000,
    },
  },
});

const App = () => (
  <HashRouter>
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <SnackbarProvider maxSnack={3}>
        <Admin
          title="Elax"
          dashboard={InitialRouter}
          dataProvider={dataProvider}
          authProvider={authProvider}
          queryClient={queryClient}
          lightTheme={elaxTheme}
          darkTheme={null}
          layout={Layout}
          store={userStore}
          loginPage={
            <NoLayoutCard>
              <LoginForm />
              <Typography variant="caption">
                <Link to="/forgot-password">Forgot password?</Link>
              </Typography>
            </NoLayoutCard>
          }
        >
          {Object.entries(resources).map(([name, resource]) => (
            <Resource
              key={name}
              name={name}
              {...mapValues(resource, (val, key) => {
                if (["create", "edit", "list", "show"].includes(key)) {
                  return withPageTitle(val);
                }
                return val;
              })}
            >
              {resource.routes}
            </Resource>
          ))}
          <Resource name="documents" />
          <Resource name="status-frames" />
          <Resource name="data-frames" />
          <Resource name="hardware-versions" />

          <Resource name="alert-codes" />

          <CustomRoutes>
            <Route path="/dashboard/*" element={<Dashboard />} />
          </CustomRoutes>
          <CustomRoutes noLayout>
            <Route path="/set-password" element={<SetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </CustomRoutes>
        </Admin>
      </SnackbarProvider>
    </QueryParamProvider>
  </HashRouter>
);

const InitialRouter = () => {
  const redirect = useRedirect();
  useEffect(() => {
    redirect("/dashboard");
  }, [redirect]);

  return <></>;
};

export default App;
