import { SelectInput } from "react-admin";
import { useFormContext } from "react-hook-form";
import type { Intervention } from "../types";
import { InterventionType } from "../types";

export const InterventionTypeInput = () => {
  const { setValue } = useFormContext<Intervention>();

  return (
    <SelectInput
      source="type"
      label="Type"
      choices={Object.values(InterventionType).map((value) => ({
        id: value,
        name: value,
      }))}
      fullWidth
      onChange={(event) => {
        if (event.target.value !== InterventionType.REMOVAL) {
          setValue("deviceLocationAfterRemoval", undefined);
          setValue("removalResponsible", undefined);
        }
        if (event.target.value !== InterventionType.REPLACEMENT) {
          setValue("replacedDeviceId", undefined);
        }
      }}
    />
  );
};
