import Icon from "@mui/icons-material/Contactless";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useRecordContext } from "react-admin";
import CardWithIcon from "../../../layout/CardWithIcon";

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const TelecomField = () => {
  const record = useRecordContext();

  return (
    <CardWithIcon
      icon={Icon}
      color="#3f51b5"
      data={
        record
          ? [
              {
                label: "Last Message",
                value: record.latestDataFrame
                  ? dayjs().to(record.latestDataFrame.ts)
                  : "-",
              },
              {
                label: "Telemetry",
                value: `${record.Telemetry} minutes`,
              },
              {
                label: "Lost connection after",
                value: dayjs
                  .duration(
                    record.dataFrameFrequencyAlertThresholdInMinutes,
                    "minutes",
                  )
                  .humanize(),
              },
              {
                label: "SIM ICC ID",
                value: record.SimICCId,
              },
            ]
          : []
      }
    />
  );
};
