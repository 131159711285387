import { getErrorMessage } from "@elax/utils";
import { useNotify, useRefresh, useUpdate } from "react-admin";

export const useUpdateWithNotification = <Record>(
  resource: string,
  id: string | undefined,
  data: Partial<Record>,
) => {
  const refresh = useRefresh();
  const notify = useNotify();

  return useUpdate(
    resource,
    {
      id,
      data,
    },
    {
      onSuccess: () => {
        refresh();
        notify("Done");
      },
      onError: (error: unknown) =>
        notify(`Error: ${getErrorMessage(error)}`, { type: "error" }),
    },
  );
};
