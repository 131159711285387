import { IncidentActor, IncidentStatus } from "@elax/business-types";
import { Box, Chip, Stack, Tabs } from "@mui/material";
import { blue, brown } from "@mui/material/colors";
import {
  Datagrid,
  DatagridConfigurable,
  DateField,
  FilterButton,
  FunctionField,
  Link,
  List,
  ReferenceArrayField,
  ReferenceField,
  ReferenceManyField,
  SelectColumnsButton,
  TextField,
  TopToolbar,
  WrapperField,
} from "react-admin";
import { EnumField } from "../../../fields/EnumField";
import { TimeAgoField } from "../../../fields/TimeAgoField";
import { useURLFilter } from "../../../filters/useURLFilter";
import { ActionsMenu } from "../../../layout/actions-menu";
import TagArrayField from "../../device-tags/fields/TagArrayField";
import { ContractTypeField } from "../../devices/fields/ContractTypeField";
import Aside from "../../devices/show/aside/Aside";
import { DataTab } from "../../devices/show/tabs/data";
import { CreateIncident } from "../create/CreateIncident";
import { IncidentActionMenu } from "../edit/ActionsMenu";
import { AssignedToField } from "../fields/AssignedToField";
import { IncidentHistory } from "../fields/Events";
import { HasIncidentCommunicationSent } from "../fields/HasIncidentCommunicationSent";
import { IncidentTypeField } from "../fields/IncidentTypeField";
import { IncidentTag } from "../fields/Tag";
import type { Incident } from "../types";
import { IncidentAssignedToTab } from "./IncidentAssignedToTab";
import { ExportIncidents } from "./actions/Export";
import { incidentFilters } from "./filters";

export const IncidentList = () => {
  const [assignedTo, setAssignedTo] = useURLFilter("assignedTo");
  const [status] = useURLFilter("status");
  return (
    <>
      <List
        perPage={20}
        sort={{ field: "createdAt", order: "DESC" }}
        filter={{
          assignedTo: assignedTo ?? IncidentActor.UNIDENTIFIED,
          status: status ?? IncidentStatus.OPENED,
        }}
        filters={incidentFilters}
        actions={
          <TopToolbar>
            <FilterButton />
            <SelectColumnsButton />
            <ActionsMenu>
              <CreateIncident />
              <ExportIncidents />
            </ActionsMenu>
          </TopToolbar>
        }
      >
        <Tabs
          value={assignedTo ?? IncidentActor.UNIDENTIFIED}
          onChange={(_, value) => {
            setAssignedTo(value);
          }}
          variant="fullWidth"
        >
          {Object.values(IncidentActor).map((actor) => (
            <IncidentAssignedToTab assignedTo={actor} value={actor} />
          ))}
        </Tabs>
        <DatagridConfigurable
          rowClick={false}
          omit={["Device Contract Type", "Interventions"]}
          bulkActionButtons={false}
          expand={
            <ReferenceField reference="devices" source="deviceId" link={false}>
              <Box display="flex">
                <Box flexGrow={1}>
                  <DataTab />
                </Box>
                <Aside />
              </Box>
            </ReferenceField>
          }
        >
          <TimeAgoField source="createdAt" label="Start Date" />
          <TimeAgoField source="updatedAt" label="Last Update" />
          <FunctionField<Incident>
            label="Device"
            render={(record) => (
              <>
                <Link to={`/devices/${record.device?.id}/show`}>
                  {record?.device?.name}
                </Link>
              </>
            )}
          />
          <ContractTypeField
            source="device.contractType"
            label="Device Contract Type"
          />
          <DateField
            label="Installation Date"
            source="device.installationDate"
            sortable={false}
          />
          <AssignedToField label="Assigned To" />
          <EnumField
            label="Priority"
            colors={{ 1: blue[900], 2: blue[700], 3: blue[500], 4: blue[300] }}
            labels={{ 1: "P1", 2: "P2", 3: "P3", 4: "P4" }}
            source="priority"
            emptyText="N/A"
          />
          <WrapperField label="Tags">
            <FunctionField<Incident>
              label="Tags"
              render={(record) =>
                record?.tags?.map((tag) => (
                  <IncidentTag key={tag} value={tag} />
                ))
              }
            />
            <FunctionField<Incident>
              render={(incident) =>
                incident?.device?.endUsers.length ? null : (
                  <Chip
                    label="Pas de résident"
                    sx={{
                      backgroundColor: brown["100"],
                      color: brown["800"],
                      borderRadius: 1,
                      m: 0.5,
                      height: 22,
                    }}
                  />
                )
              }
            />
            <ReferenceArrayField
              label="Tags"
              reference="device-tags"
              source="device.tags"
            >
              <TagArrayField />
            </ReferenceArrayField>
          </WrapperField>
          <ReferenceField
            reference="maintenance-providers"
            source="maintenanceProviderId"
            label="Maintenance Provider"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>

          <ReferenceManyField
            reference="interventions"
            target="incidentId"
            label="Interventions"
            sort={{ field: "createdAt", order: "DESC" }}
          >
            <Datagrid bulkActionButtons={false} hover={false}>
              <TextField source="status" />
              <FunctionField<Incident>
                label={"Mission\u00A0Order"}
                render={(record) =>
                  (record.missionOrder?.split("-")[0] ?? "").slice(0, 5)
                }
              />
            </Datagrid>
          </ReferenceManyField>
          <IncidentTypeField label="Type" />
          <WrapperField label="Actions">
            <Stack direction="row" alignItems="center">
              <IncidentHistory />
              <IncidentActionMenu />
              <HasIncidentCommunicationSent />
            </Stack>
          </WrapperField>
        </DatagridConfigurable>
      </List>
    </>
  );
};
