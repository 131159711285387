import { SelectInput } from "react-admin";
import { useFormContext } from "react-hook-form";
import type { Intervention } from "../types";
import {
  InterventionSourcingStatus,
  InterventionStatus,
  InterventionVisitStatus,
  interventionPossibleSourcingStatusByStatus,
  interventionStatusLabels,
} from "../types";
import { interventionPossibleVisitStatusByStatus } from "./InterventionVisitStatusInput";

export const InterventionStatusInput = () => {
  const { setValue } = useFormContext<Intervention>();

  return (
    <SelectInput
      source="status"
      label="Status"
      choices={Object.values(InterventionStatus).map((value) => ({
        id: value,
        name: interventionStatusLabels[value],
      }))}
      fullWidth
      onChange={(event) => {
        setValue(
          "sourcingStatus",
          event.target.value
            ? interventionPossibleSourcingStatusByStatus[
                event.target.value as InterventionStatus
              ][0] ?? InterventionSourcingStatus.NOTHING_TO_REPORT
            : InterventionSourcingStatus.NOTHING_TO_REPORT,
        );
        setValue(
          "visitStatus",
          event.target.value
            ? interventionPossibleVisitStatusByStatus[
                event.target.value as InterventionStatus
              ][0] ?? InterventionVisitStatus.NOTHING_TO_REPORT
            : InterventionVisitStatus.NOTHING_TO_REPORT,
        );

        if (event.target.value !== InterventionStatus.SUCCESS) {
          setValue("deviceId", undefined);
          setValue("replacedDeviceId", undefined);
        }
      }}
    />
  );
};
