import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import CalendarMonth from "@mui/icons-material/CalendarMonth";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Popover,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { DateRange } from "react-date-range";

type Range = {
  startDate?: Date;
  endDate?: Date;
};
type DateRangePickerProps = {
  range: Range;
  onChange: (range: Range) => void;
  label?: string;
  referencePeriod?: Range;
};
export const DateRangePicker = ({
  range,
  onChange,
  label,
  referencePeriod,
}: DateRangePickerProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const value =
    range.startDate && range.endDate
      ? `${dayjs(range.startDate).format("DD/MM/YYYY")} -> ${dayjs(
          range.endDate,
        ).format("DD/MM/YYYY")}`
      : "";

  const ranges: any = [
    {
      ...range,
      key: "selection",
    },
  ];
  if (referencePeriod) {
    ranges.push({
      key: "reference",
      disabled: true,
      startDate: referencePeriod.startDate,
      endDate: referencePeriod.endDate,
    });
  }

  return (
    <>
      <DateField
        label={label || "From -> To"}
        value={value}
        onClick={handleClick}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <DateRange
          ranges={ranges}
          onChange={({ selection }) => {
            onChange({
              startDate: dayjs(selection.startDate || new Date())
                .startOf("day")
                .toDate(),
              endDate: dayjs(selection.endDate || new Date())
                .endOf("day")
                .toDate(),
            });
          }}
          onRangeFocusChange={([index, step]) => {
            if (index === 0 && step === 0) {
              handleClose();
            }
          }}
          months={2}
          displayMode="dateRange"
          showMonthAndYearPickers={false}
          showDateDisplay={false}
          weekStartsOn={1}
          direction="horizontal"
        />
      </Popover>
    </>
  );
};

const DateField = ({
  value,
  onClick,
  label,
  sx,
}: {
  label: string;
  value: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  sx?: any;
}) => (
  <FormControl variant="outlined" sx={sx}>
    <InputLabel>{label}</InputLabel>
    <OutlinedInput
      size="small"
      value={value}
      endAdornment={
        <InputAdornment position="end">
          <IconButton aria-label="show calendar" onClick={onClick} edge="end">
            <CalendarMonth />
          </IconButton>
        </InputAdornment>
      }
      label={label}
    />
  </FormControl>
);
