import BalancingBidIcon from "@mui/icons-material/StackedLineChart";
import { BalancingBidShow } from "./show";

export const balancingBidResource = {
  icon: BalancingBidIcon,
  edit: undefined,
  show: BalancingBidShow,
  create: undefined,
  options: { label: "Offres d'ajustement" },
};

export { default as BalancingBidIcon } from "@mui/icons-material/StackedLineChart";
