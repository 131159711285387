import { CircularProgress, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useRecordContext } from "react-admin";
import { useMeasure } from "react-use";
import { useCommunicationDetails } from "../data";
import type { Communication } from "../type";

const initialHeight = 1200;

export function CommunicationPreview() {
  const communication = useRecordContext<Communication>();

  const { data, isLoading } = useCommunicationDetails(communication?.id);

  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = useState(initialHeight);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (data?.preview) {
    return (
      <div ref={ref}>
        {data.preview.title && (
          <Typography variant="h5" textAlign="center" marginTop={3}>
            <a href={data.editionLink} target="_blank" rel="noreferrer">
              {data.preview.title}
            </a>
          </Typography>
        )}
        {data.preview.type === "text" ? (
          <Typography variant="body1" textAlign="center" marginTop={3}>
            {data.preview.content}
          </Typography>
        ) : data.preview.type === "pdf" ? (
          <iframe
            ref={iframeRef}
            frameBorder={0}
            width={width}
            height={1200}
            src={`data:application/pdf;base64,${data.preview.content}#navpanes=0`}
            scrolling="no"
          ></iframe>
        ) : data.preview.type === "html" ? (
          <iframe
            ref={iframeRef}
            frameBorder={0}
            width={width}
            height={height}
            scrolling="no"
            srcDoc={data.preview.content}
            onLoad={() => {
              try {
                setHeight(
                  iframeRef.current?.contentWindow?.document?.body
                    ?.scrollHeight ?? initialHeight,
                );
              } catch {
                // on cross origin document is not accessible
              }
            }}
          ></iframe>
        ) : (
          `No preview available`
        )}
      </div>
    );
  }

  return null;
}
