import { Paper } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useRedirect } from "react-admin";
import { dataProvider } from "../../../providers/data";
import { Chart } from "../../Chart";

export const InterventionsGraph = () => {
  const { data } = useQuery({
    queryFn: () => dataProvider.getInterventionAnalytics(),
    queryKey: [`interventions/analytics`],
  });
  const redirect = useRedirect();

  return (
    <Paper elevation={1}>
      <Chart
        isLoading={!data}
        options={{
          title: {
            text: "Installations",
          },
          subtitle: {
            text:
              data &&
              `Last Update: ${dayjs().to(data.lastUpdateDate)} - ${
                data.data.usedDevicesCount
              } devices in use`,
          },
          chart: {
            type: "area",
            zooming: {
              type: "x",
            },
          },
          plotOptions: {
            area: {
              stacking: "normal",
              lineWidth: 0,
              marker: {
                enabled: false,
              },
            },
            series: {
              events: {
                click: (event) => {
                  redirect(
                    `interventions/${(event.point as any).custom.id}/show`,
                  );
                },
              },
            },
          },
          xAxis: {
            crosshair: true,
            type: "datetime",
            tickPixelInterval: 150,
          },
          yAxis: {
            title: {
              text: "",
            },
          },
          series: data && [
            {
              name: "Active devices",
              turboThreshold: data.data.installations.length,
              type: "spline",
              color: "#2196f3",
              data: data.data.installations.map((point) => ({
                x: point.timestamp,
                y: point.v1Count + point.v2Count,
              })),
            },
            {
              name: "Devices V2",
              type: "area",
              turboThreshold: data.data.installations.length,
              data: data.data.installations.map((point) => ({
                x: point.timestamp,
                y: point.v2Count,
                custom: {
                  id: point.id,
                },
              })),
              color: "#ffc107",
              fillOpacity: 0.5,
            },
            {
              name: "Devices V1",
              type: "area",
              turboThreshold: data.data.installations.length,
              data: data.data.installations.map((point) => ({
                x: point.timestamp,
                y: point.v1Count,
                custom: {
                  id: point.id,
                },
              })),
              color: "#9e9e9e",
              fillOpacity: 0.5,
            },
          ],
        }}
      />
    </Paper>
  );
};
