import ComputerIcon from "@mui/icons-material/Computer";
import LinkIcon from "@mui/icons-material/Link";
import { IconButton } from "@mui/material";
import { useGetIdentity } from "react-admin";

export const DevProdLinker = () => {
  const { identity } = useGetIdentity();

  const display = identity?.isDev;

  if (display && window.location.host.includes("localhost")) {
    return (
      <IconButton
        onClick={() => {
          navigator.clipboard.writeText(
            `https://back-office.elaxenergy.com/${window.location.hash}`,
          );
        }}
        color="inherit"
      >
        <LinkIcon />
      </IconButton>
    );
  }

  if (display) {
    return (
      <IconButton
        color="inherit"
        href={`http://localhost:3000/${window.location.hash}`}
      >
        <ComputerIcon />
      </IconButton>
    );
  }

  return <></>;
};
