import Icon from "@mui/icons-material/Memory";
import dayjs from "dayjs";
import { useRecordContext } from "react-admin";
import CardWithIcon from "../../../layout/CardWithIcon";
import { RequestFOTA } from "../show/actions/send-config-flags";
import BarcodeField from "./BarcodeField";
import QRCodeField from "./QRCodeField";

export const HardwareField = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  const isUpToDate =
    record.targetFirmwareVersion === record.firmwareVersion ||
    !record.targetFirmwareVersion;

  const lastUpdate =
    record.lastFirmwareUpdateRequest &&
    dayjs(new Date(record.lastFirmwareUpdateRequest)).format(
      "YYYY-MM-DD HH:mm",
    );

  const firmwareValue = `${record.firmwareVersion}${
    isUpToDate
      ? ""
      : ` (currently updating to
    ${record.targetFirmwareVersion} ~ last try at ${lastUpdate})`
  }`;

  return (
    <CardWithIcon
      icon={Icon}
      color="#8bc34a"
      topRightContent={<QRCodeField />}
      data={
        record
          ? [
              {
                label: "IMEI",
                value: `${record.IMEI}`,
                after: <BarcodeField />,
              },
              {
                label: "Hardware Version",
                value: record.VersionHardware,
              },
              {
                label: "Firmware Version",
                value: firmwareValue,
              },
            ]
          : []
      }
      actions={isUpToDate ? null : <RequestFOTA />}
    />
  );
};
