import { useAxiosQuery } from "../../../../../../providers/data-hooks/useAxiosQuery";

export const useComfortData = (deviceId: string | undefined) => {
  const query = useAxiosQuery<{
    currentConfig: {
      deltaNightTemperatureInC: number;
      deltaDayTemperatureInC: number;
    };
    dayComfort: {
      recommendedTemperatureInC: number;
      minSelectableTemperatureInC: number;
      maxSelectableTemperatureInC: number;
    };
    nightComfort: {
      recommendedTemperatureInC: number;
      minSelectableTemperatureInC: number;
      maxSelectableTemperatureInC: number;
    } | null;
  }>(
    {
      url: `/devices/${deviceId}/comfort-configs`,
    },
    { enabled: !!deviceId },
  );

  return query;
};
