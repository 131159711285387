import { Typography } from "@mui/material";
import { EditInDialogButton } from "@react-admin/ra-form-layout";
import {
  Datagrid,
  DeleteWithConfirmButton,
  ReferenceManyField,
} from "react-admin";
import { RecordDetails } from "../../../activity-logs/RecordDetails";
import { ResidenceContactEditionForm } from "../../../residence-contact/Edit";
import { residenceContactFields } from "../../../residence-contact/fields";

export function ResidenceContacts() {
  return (
    <>
      <Typography variant="h6">Residence contacts</Typography>
      <ReferenceManyField
        reference="residence-contacts"
        target="organizationId"
        perPage={1000}
      >
        <Datagrid bulkActionButtons={false}>
          {residenceContactFields.role}
          {residenceContactFields.firstName}
          {residenceContactFields.lastName}
          {residenceContactFields.jobTitle}
          {residenceContactFields.company}
          {residenceContactFields.email}
          {residenceContactFields.phone}
          {residenceContactFields.residences}
          {residenceContactFields.createdAt}
          {residenceContactFields.updatedAt}
          <div style={{ display: "flex" }}>
            <EditInDialogButton label="">
              <ResidenceContactEditionForm />
            </EditInDialogButton>
            <DeleteWithConfirmButton redirect={false} label="" />
          </div>
          <RecordDetails />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
}
