import type { SliderValueLabelProps } from "@mui/material";
import { Slider, Tooltip } from "@mui/material";
import { blue, teal } from "@mui/material/colors";
import { range } from "lodash";

type ComfortSliderProps = {
  minValue: number;
  maxValue: number;
  recommendedValue: number;
  deltaValue: number;
  onChange?: (deltaValue: number) => void;
};

export const ComfortSlider = ({
  minValue,
  maxValue,
  recommendedValue,
  deltaValue = 0,
  onChange,
}: ComfortSliderProps) => {
  const value = recommendedValue + deltaValue;
  const left =
    (Math.min(recommendedValue, value) - minValue) / (maxValue - minValue);
  const size = Math.abs(value - recommendedValue) / (maxValue - minValue);
  const isDisabled = !onChange;

  return (
    <Slider
      value={value}
      onChange={
        onChange &&
        ((_, newValue) => onChange((newValue as number) - recommendedValue))
      }
      disabled={isDisabled}
      min={minValue}
      max={maxValue}
      defaultValue={recommendedValue}
      step={1}
      marks={range(minValue, maxValue, 1).map((value) => ({
        value,
        label:
          value === recommendedValue ? <RecoCursor left={left} /> : undefined,
      }))}
      valueLabelDisplay="auto"
      slotProps={{
        track: {
          style: {
            left: `${left * 100}%`,
            width: `${size * 100}%`,
            color: blue[800],
          },
        },
        thumb: {
          style: {
            height: 15,
            width: 15,
            backgroundColor: blue[800],
          },
        },
      }}
      slots={{
        valueLabel: TooltipLabel,
      }}
    />
  );
};

const TooltipLabel = (
  props: SliderValueLabelProps & { ownerState: { defaultValue: number } },
) => {
  const { children, value, ownerState } = props;
  const diff = ownerState ? value - ownerState?.defaultValue : 0;

  return (
    <Tooltip
      placement="top"
      title={
        diff ? `${diff > 0 ? "+" : ""}${diff}°C (${value}°C)` : "Reco Elax"
      }
    >
      {children}
    </Tooltip>
  );
};

type RecoCursorProps = {
  left: number;
};

const RecoCursor = (props: RecoCursorProps) => {
  const size = 7;
  return (
    <Tooltip title="Reco Elax">
      <span
        style={{
          left: `${props.left * 100}%`,
          top: -25,
          width: "0px",
          height: "0px",
          borderStyle: "solid",
          borderWidth: `0 ${size}px ${size * 1.5}px ${size}px`,
          borderColor: `transparent transparent ${teal[400]} transparent`,
          transform: "translate(-50%, -50%) rotate(180deg)",
          position: "absolute",
        }}
      ></span>
    </Tooltip>
  );
};
