import type { Document } from "./types";

const OVH_BUCKET_URL =
  "https://storage.gra.cloud.ovh.net/v1/AUTH_091312b8bed64ca4b4238e53be6f0fdd";

export const getDocumentURL = (bucketName: string, document?: Document) => {
  return (
    OVH_BUCKET_URL +
    "/" +
    bucketName +
    "/" +
    document?.id +
    "." +
    document?.extension
  );
};
