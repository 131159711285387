import { useQuery } from "@tanstack/react-query";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { merge } from "lodash";
import { useEffect, useRef } from "react";
import { useRecordContext } from "react-admin";
import { dataProvider } from "../../../../../providers/data";
import type { Device, SeriesName } from "../../../types";
import { NoData } from "./charts/NoData";
import { updateData } from "./charts/updateData";

type Datum = {
  timestamp: number;
  energy: number;
  energy_mean: number;
};

type EnergyChartProps = {
  startDate: Date;
  endDate: Date;
  displayedSeries?: SeriesName[];
  paramsOptions: Highcharts.Options;
};

export const EnergyChart = ({
  startDate,
  endDate,
  displayedSeries,
  paramsOptions,
}: EnergyChartProps) => {
  const record = useRecordContext<Device>();

  const { data } = useQuery<Datum[]>({
    queryFn: () => dataProvider.getEnergy(record?.id ?? "", startDate, endDate),
    queryKey: ["energy", record?.id, startDate.getTime(), endDate.getTime()],
    enabled: Boolean(record),
  });

  const chartRef = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    if (!data || !record || !chartRef.current) {
      return;
    }

    const series = [
      {
        name: "Energy Reference Filtered",
        type: "column" as const,
        color: "#7CB5EC",
        data: data
          .map((datum) => [
            datum.timestamp,
            Number(
              record.referenceEnergyPerDay[
                new Date(datum.timestamp).getUTCDay()
              ],
            ),
          ])
          .reverse(),
        display: true,
      },
      {
        name: "Energy Reference Filtered (avg 1 week)",
        color: "#8085E9",
        type: "line" as const,
        data: data
          .map((datum) => [
            datum.timestamp,
            record.referenceEnergyPerDay.reduce(
              (a: number, b: number) => a + b,
              0,
            ) / 7,
          ])
          .reverse(),
        display: true,
      },
      {
        name: "Energy Reference Measured",
        type: "column" as const,
        color: "#434348",
        data: data
          .map((datum) => [
            datum.timestamp,
            Number(
              record.referenceEnergyPerDayOnTimer[
                new Date(datum.timestamp).getUTCDay()
              ],
            ),
          ])
          .reverse(),
        display: true,
      },
      {
        name: "Energy Measured",
        type: "column" as const,
        color: "#F7A35C",
        data: data
          .map((datum) => [datum.timestamp, datum.energy || 0])
          .reverse(),
        display: true,
      },
      {
        name: "Energy Reference Measured (avg 1 week)",
        color: "#F15C80",
        type: "line" as const,
        data: data
          .map((datum) => [
            datum.timestamp,
            record.referenceEnergyPerDayOnTimer.reduce(
              (a: number, b: number) => a + b,
              0,
            ) / 7,
          ])
          .reverse(),
        display: true,
      },
      {
        name: "Energy Measured (avg 1 week)",
        color: "#2B908F",
        type: "line" as const,
        data: data
          .map((datum) => [datum.timestamp, datum.energy_mean])
          .reverse(),
        display: true,
      },
    ];

    updateData(
      series
        .filter((serie) => serie.display)
        .map((serie) => ({
          ...serie,
          visible:
            !displayedSeries ||
            displayedSeries.includes(serie.name as SeriesName),
        })),
      chartRef.current.chart,
      { start: startDate, end: endDate },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    record,
    startDate,
    endDate,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(displayedSeries),
  ]);

  if (data && data.length === 0) {
    return <NoData />;
  }

  return (
    <HighchartsReact
      ref={chartRef}
      highcharts={Highcharts}
      options={merge(options, paramsOptions)}
    />
  );
};

const options: Highcharts.Options = {
  chart: {
    zooming: {
      type: "x",
    },
    animation: false,
  },
  series: [],
  title: {
    text: "Energy evolution",
  },
  tooltip: {
    xDateFormat: "%d-%m %H:%M",
  },
  xAxis: {
    type: "datetime",
    tickPixelInterval: 150,
  },
  yAxis: {
    minPadding: 0.2,
    maxPadding: 0.2,
    title: {
      text: "Energy [W.h]",
      margin: 10,
    },
  },
  time: {
    useUTC: false,
  },
};
