import CommunicationIcon from "@mui/icons-material/Email";
import dayjs from "dayjs";
import { CommunicationList } from "./List";
import { CommunicationShow } from "./Show";
import { communicationTopicConfigs } from "./type";
import type { Communication } from "./type";

export const communicationResource = {
  icon: CommunicationIcon,
  options: { label: "Communications" },
  list: CommunicationList,
  show: CommunicationShow,
  recordRepresentation: (record: Communication) =>
    `${record.medium} ${
      communicationTopicConfigs[record.topic].label
    } du ${dayjs(record.date).format("DD/MM/YYYY")}`,
};

export { default as CommunicationIcon } from "@mui/icons-material/Email";
