import { Alert, Divider, Grid } from "@mui/material";
import {
  ReferenceManyField,
  WithListContext,
  useRecordContext,
} from "react-admin";
import { ContractType } from "../../../layout/dashboard/widgets/ContractType";
import { DevicesState } from "../../../layout/dashboard/widgets/DevicesState";
import { HealthRisk } from "../../../layout/dashboard/widgets/HealthRisk";
import { HeatersState } from "../../../layout/dashboard/widgets/HeaterState";
import { V1Alert } from "../../devices/reporting/V1Alert";
import type { Project } from "../../project/type";
import type { Deal } from "../type";

export const DealOverviewTab = () => {
  const deal = useRecordContext<Deal>();

  if (!deal) {
    return null;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {deal && <V1Alert filter={{ dealId: deal.id }} />}
        </Grid>
        <Grid item xs={12}>
          <ReferenceManyField reference="projects" target="dealId" source="id">
            <WithListContext<Project>
              render={({ data }) =>
                data && data.length > 0 ? (
                  <Alert severity="info" sx={{ margin: 1 }}>
                    Retrouvez le dashboard des économies pour le projet sur
                    metabase:{" "}
                    <a
                      href={`https://metabase.elaxenergy.com/dashboard/52-dashboard-principal-sur-le-parc?tab=64-estimations-annuelles-%C3%A9conomie-par-bailleur${data
                        .map(
                          (project) =>
                            `&projet=${encodeURIComponent(project.name)}`,
                        )
                        .join("")}`}
                    >
                      Voir le dashboard metabase
                    </a>
                  </Alert>
                ) : null
              }
            />
          </ReferenceManyField>
        </Grid>

        <HealthRisk filter={deal && { dealId: deal.id }} />
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} md={6}>
          <ContractType filter={deal && { dealId: deal.id }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <HeatersState filter={deal && { dealId: deal.id }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DevicesState filter={deal && { dealId: deal.id }} />
        </Grid>
      </Grid>
    </>
  );
};
