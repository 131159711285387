import { getErrorMessage } from "@elax/utils";
import { Button, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useNotify } from "react-admin";
import { dataProvider } from "../../providers/data";
import { NoLayoutCard } from "../NoLayoutCard";

export const ForgotPassword = () => {
  const notify = useNotify();

  const [email, setEmail] = useState("");
  const mutation = useMutation({
    mutationFn: async () => {
      try {
        await dataProvider.forgotPassword(email);
        notify("An email has been sent with a link to reset password");
      } catch (error) {
        notify(`An error occured: ${getErrorMessage(error)}`, {
          type: "error",
        });
      }
    },
  });

  return (
    <NoLayoutCard>
      <TextField
        type="email"
        size="small"
        label="Email"
        value={email}
        fullWidth
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        variant="contained"
        sx={{ marginY: 2 }}
        onClick={() => {
          mutation.mutate();
        }}
        disabled={mutation.isPending}
      >
        Forgot Password
      </Button>
    </NoLayoutCard>
  );
};
