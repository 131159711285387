import {
  DateField,
  FunctionField,
  ReferenceField,
  TextField,
} from "react-admin";
import type { ResidenceContact } from "../types";

export const residenceContactFields = {
  organization: (
    <ReferenceField
      label="Organization"
      source="organizationId"
      reference="organizations"
    >
      <TextField label="Name" source="name" />
    </ReferenceField>
  ),
  role: <TextField label="Role" source="type" />,
  jobTitle: <TextField label="Job Title" source="jobTitle" />,
  firstName: <TextField label="First Name" source="firstName" />,
  lastName: <TextField label="Last Name" source="lastName" />,
  name: (
    <FunctionField<ResidenceContact>
      label="Name"
      render={(record) =>
        (record?.firstName || record?.lastName) &&
        `${record.firstName || ""} ${record.lastName || ""}`
      }
    />
  ),
  company: <TextField label="Company" source="company" />,
  email: <TextField label="Email" source="email" />,
  phone: <TextField label="Phone" source="phone" />,
  residences: (
    <FunctionField<ResidenceContact>
      label="Residences"
      render={(record) => record.residences.length}
    />
  ),
  createdAt: <DateField label="Created At" source="createdAt" />,
  updatedAt: <DateField label="Updated At" source="updatedAt" />,
};
