import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { Confirm, useRecordContext } from "react-admin";
import { useUpdateWithNotification } from "../../../providers/data-hooks/useUpdateWithNotification";
import type { Residence } from "../../residence/types";
import type { ResidenceContact } from "../types";

export const UnlinkResidenceButton = ({
  residenceContact,
}: {
  residenceContact: ResidenceContact;
}) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const residence = useRecordContext<Residence>();

  const [unlinkResidence] = useUpdateWithNotification(
    "residence-contacts",
    residenceContact.id,
    {
      residences: residenceContact.residences.filter(
        (residenceId) => residenceId !== residence?.id,
      ),
    },
  );

  return (
    <IconButton onClick={() => setIsConfirmOpen(true)}>
      <DeleteIcon color="error" />
      <Confirm
        isOpen={isConfirmOpen}
        title="Unlink residence"
        content="The contact and the residence won't be deleted."
        onConfirm={async () => {
          await unlinkResidence();
          setIsConfirmOpen(false);
        }}
        onClose={() => setIsConfirmOpen(false)}
      />
    </IconButton>
  );
};
