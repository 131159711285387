import DeviceIcon from "@mui/icons-material/SpeakerPhone";
import { EditDevice } from "./Edit";
import { DeviceList } from "./List";
import { ShowDevice } from "./show";
import type { Device } from "./types";

export const deviceResource = {
  list: DeviceList,
  show: ShowDevice,
  edit: EditDevice,
  icon: DeviceIcon,
  recordRepresentation: (record: Device) => `🖱 ${record.name}`,
};

export { default as DeviceIcon } from "@mui/icons-material/SpeakerPhone";
