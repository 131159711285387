import DemandResponseEntityIcon from "@mui/icons-material/GroupWork";
import { DemandResponseEntityCreate, DemandResponseEntityEdit } from "./Edit";
import { DemandResponseEntityList } from "./List";
import { DemandResponseEntityShow } from "./show";
import type { DemandResponseEntity } from "./type";

export const demandResponseEntityResource = {
  icon: DemandResponseEntityIcon,
  list: DemandResponseEntityList,
  edit: DemandResponseEntityEdit,
  show: DemandResponseEntityShow,
  create: DemandResponseEntityCreate,
  options: { label: "EDE" },
  recordRepresentation: (record: DemandResponseEntity) => record.name,
};

export { default as DemandResponseEntityIcon } from "@mui/icons-material/GroupWork";
