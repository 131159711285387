import { Box, Button, Paper, Popper, Slider } from "@mui/material";
import { useState } from "react";
import { useURLFilter } from "./useURLFilter";

export default function RangeSliderFilter({
  source,
  label,
  min,
  max,
  step,
}: {
  source: string;
  label: string;
  min: number;
  max: number;
  step: number;
}) {
  const [value, setValue] = useURLFilter<any>(source);
  const arrayValue = [value?.$gte || min, value?.$lte || max];

  const [sliderDisplay, setSliderDisplay] = useState(false);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const handleSliderChange = (_event: Event, newValue: number | number[]) => {
    const [$gte, $lte] = newValue as number[];
    setValue({ $gte, $lte });
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
    setSliderDisplay((state) => !state);
  };

  return (
    <Box>
      <Popper
        open={sliderDisplay}
        anchorEl={anchor}
        placement="bottom-start"
        sx={{ zIndex: 50 }}
      >
        <Paper elevation={3} sx={{ padding: 2, width: 300 }}>
          <Slider
            value={arrayValue}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            min={min}
            max={max}
            step={step}
          />
        </Paper>
      </Popper>
      <Button
        onClick={handleButtonClick}
        variant="outlined"
        color="inherit"
        sx={{ textTransform: "none" }}
      >{`${label} : [${arrayValue.join(", ")}]`}</Button>
    </Box>
  );
}
