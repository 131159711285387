import { Alert, Divider, Grid } from "@mui/material";
import { useRecordContext } from "react-admin";
import { ContractType } from "../../../../../layout/dashboard/widgets/ContractType";
import { DevicesState } from "../../../../../layout/dashboard/widgets/DevicesState";
import { HealthRisk } from "../../../../../layout/dashboard/widgets/HealthRisk";
import { HeatersState } from "../../../../../layout/dashboard/widgets/HeaterState";
import { V1Alert } from "../../../../devices/reporting/V1Alert";
import type { Project } from "../../../type";

export const ProjectOverview = () => {
  const project = useRecordContext<Project>();

  if (!project) {
    return null;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert severity="info" sx={{ margin: 1 }}>
            Retrouvez le dashboard des économies pour ce projet sur metabase:{" "}
            <a
              href={`https://metabase.elaxenergy.com/dashboard/52-dashboard-principal-sur-le-parc?tab=64-estimations-annuelles-%C3%A9conomie-par-bailleur&projet=${encodeURIComponent(
                project.name,
              )}`}
            >
              Voir le dashboard metabase
            </a>
          </Alert>
        </Grid>

        <Grid item xs={12}>
          {project && <V1Alert filter={{ projectId: project.id }} />}
        </Grid>

        <HealthRisk filter={project && { projectId: project.id }} />
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} md={6}>
          <ContractType filter={project && { projectId: project.id }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <HeatersState filter={project && { projectId: project.id }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DevicesState filter={project && { projectId: project.id }} />
        </Grid>
      </Grid>
    </>
  );
};
