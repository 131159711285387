import { getErrorMessage } from "@elax/utils";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Button, CircularProgress, IconButton } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

export const useExport = (handleExport: () => Promise<string>) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return useMutation({
    mutationKey: ["export"],
    mutationFn: () => {
      enqueueSnackbar("Asking for export...", {
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
        autoHideDuration: null,
        action: (
          <>
            <CircularProgress size={15} />
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => closeSnackbar()}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        ),
      });
      return handleExport();
    },
    onSuccess: (slackUrl) => {
      closeSnackbar();
      enqueueSnackbar("Export will be ready soon, check in #bo-exports", {
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
        autoHideDuration: null,
        action: (
          <>
            <Button
              size="small"
              onClick={() => {
                window.open(slackUrl);
                closeSnackbar();
              }}
            >
              OPEN
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => closeSnackbar()}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        ),
      });
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        <Alert severity="error">{`Failed to export data: ${getErrorMessage(
          error,
        )}`}</Alert>,
      );
    },
  });
};

export { default as ExportIcon } from "@mui/icons-material/GetApp";
