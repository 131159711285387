import ResidenceContactIcon from "@mui/icons-material/ContactMail";
import { ResidenceContactEdit } from "./Edit";
import { ResidenceContactList } from "./List";
import { ResidenceContactShow } from "./show";

export const residenceContactResource = {
  icon: ResidenceContactIcon,
  list: ResidenceContactList,
  show: ResidenceContactShow,
  edit: ResidenceContactEdit,
};
