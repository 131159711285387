import ApartmentIcon from "@mui/icons-material/Apartment";
import BusinessIcon from "@mui/icons-material/Business";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LabelIcon from "@mui/icons-material/Label";
import PhoneIcon from "@mui/icons-material/Phone";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { Box, Divider, Stack, Typography } from "@mui/material";
import {
  Datagrid,
  FunctionField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  TextField,
} from "react-admin";
import { FieldGrid } from "../../../display/FieldGrid";
import { FieldItem } from "../../../display/FieldItem";
import { PageTitle } from "../../../layout/PageTitle";
import { ResidenceContactShowActions } from "../actions";
import type { ResidenceContact } from "../types";
import { UnlinkResidenceButton } from "./UnlinkResidenceButton";

export function ResidenceContactShow() {
  return (
    <Show actions={<ResidenceContactShowActions />}>
      <PageTitle />
      <FunctionField<ResidenceContact>
        render={({ firstName, lastName, email }) => (
          <Typography variant="h6" textAlign="center" margin={2}>
            {firstName && lastName ? `${firstName} ${lastName}` : email}
          </Typography>
        )}
      />
      <FieldGrid columns={2}>
        <FieldItem label="Organization" icon={<RocketLaunchIcon />}>
          <ReferenceField
            reference="organizations"
            source="organizationId"
            link="show"
          >
            <TextField source="name" variant="body1" />
          </ReferenceField>
        </FieldItem>
        <FieldItem label="Role" icon={<LabelIcon />}>
          <TextField source="type" />
        </FieldItem>

        <FieldItem label="Email" icon={<EmailOutlinedIcon />}>
          <TextField source="email" />
        </FieldItem>

        <FieldItem label="Phone number" icon={<PhoneIcon />}>
          <TextField source="phone" />
        </FieldItem>

        <FieldItem label="Job title" icon={<BusinessCenterIcon />}>
          <TextField source="jobTitle" />
        </FieldItem>

        <FieldItem label="Company" icon={<BusinessIcon />}>
          <TextField source="company" />
        </FieldItem>
      </FieldGrid>

      <Divider sx={{ marginTop: 2 }} />

      <Box sx={{ margin: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ marginLeft: 1, marginBottom: 2 }}
        >
          <ApartmentIcon />
          <Typography variant="h6">Residences</Typography>
        </Stack>

        <FunctionField<ResidenceContact>
          render={(residenceContact) => {
            return (
              <ReferenceArrayField
                reference="residences"
                source="residences"
                sort={{ field: "esi", order: "ASC" }}
                perPage={1000}
              >
                <Datagrid bulkActionButtons={false}>
                  <TextField label="ESI" source="esi" />
                  <TextField label="Address" source="address" />
                  <TextField label="City" source="city" />
                  <TextField label="Zip code" source="zipCode" />
                  <UnlinkResidenceButton residenceContact={residenceContact} />
                </Datagrid>
              </ReferenceArrayField>
            );
          }}
        />
      </Box>
    </Show>
  );
}
