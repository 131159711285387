import { Alert } from "@mui/material";
import { useRecordContext } from "react-admin";
import type { Device } from "../../../types";

export const EnergyTab = () => {
  const record = useRecordContext<Device>();
  return (
    <>
      {record && (
        <Alert severity="info" sx={{ margin: 1 }}>
          Retrouvez le dashboard associé à ce device sur metabase:{" "}
          <a
            href={`https://metabase.elaxenergy.com/dashboard/52-dashboard-principal-sur-le-parc?device_id=${encodeURIComponent(
              record.id,
            )}&tab=58-donn%C3%A9es-d%27%C3%A9nergie-par-bo%C3%AEtier`}
          >
            Voir le dashboard metabase
          </a>
        </Alert>
      )}
    </>
  );
};
