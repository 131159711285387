import { Alert, Grid } from "@mui/material";
import { ContractType } from "./widgets/ContractType";
import { DevicesState } from "./widgets/DevicesState";
import { HealthRisk } from "./widgets/HealthRisk";
import { HeatersState } from "./widgets/HeaterState";
import { MetabaseQuestion } from "./widgets/MetabaseQuestion";

export const DevicesDashboard = ({ filter }: { filter: any }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Alert severity="info" sx={{ margin: 1 }}>
          Retrouvez le dashboard des économies sur metabase:{" "}
          <a href="https://metabase.elaxenergy.com/dashboard/52-dashboard-principal-sur-le-parc">
            Voir le dashboard metabase
          </a>
        </Alert>
      </Grid>
      <Grid item xs={6}>
        <ContractType filter={filter ?? {}} />
      </Grid>
      <Grid item xs={6}>
        <HeatersState filter={filter ?? {}} />
      </Grid>
      <Grid item xs={6}>
        <DevicesState filter={filter ?? {}} />
      </Grid>
      <Grid item xs={6}>
        <HealthRisk filter={filter ?? {}} />
      </Grid>
      <Grid item xs={12} md={6}>
        <MetabaseQuestion questionId={282} />
      </Grid>
      <Grid item xs={12} md={6}>
        <MetabaseQuestion questionId={292} />
      </Grid>
    </Grid>
  );
};
