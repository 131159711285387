import type { ReactNode } from "react";
import { SelectInput } from "react-admin";
import type { SelectInputProps } from "react-admin";
import { useWatch } from "react-hook-form";
import type { Intervention } from "../types";
import {
  InterventionRemovalResponsible,
  InterventionType,
  interventionRemovalResponsibleLabels,
} from "../types";

const removalResponsibleChoices = Object.values(
  InterventionRemovalResponsible,
).map((value) => ({
  id: value,
  name: interventionRemovalResponsibleLabels[value],
}));

export function RemovalResponsibleInput(props: SelectInputProps): ReactNode {
  const interventionType = useWatch<Intervention, "type">({ name: "type" });

  if (interventionType !== InterventionType.REMOVAL) {
    return null;
  }

  return (
    <SelectInput
      source="removalResponsible"
      label="Removal responsible"
      choices={removalResponsibleChoices}
      fullWidth
      emptyText="No responsible"
      {...props}
    />
  );
}
